











































import { Vue, Prop, Component } from 'vue-property-decorator';
import ReportCardTemplate from '@/components/swine/reports/ReportCardTemplate.vue';
import { Quartiles } from '@/models/swine/report/report.model';
import { MultiCountryQuartiles } from '@/models/swine/analysis.model';

@Component({
  components: {
    ReportCardTemplate,
  },
})
export default class MultiIndustryAppIndexChart extends Vue {
  @Prop({
    type: Object,
    default: '',
  })
  countryQuartiles!: { [key: string]: Quartiles };

  details: MultiCountryQuartiles[] = [];

  standardizeAppIndex(data: number) {
    let output = data.toString();
    if (output.includes('.')) {
      while (output.split('.')[1].length < 3) {
        output += '0';
      }
      return output;
    } else {
      output += '.000';
      return output;
    }
  }

  mounted() {
    if (this.countryQuartiles) {
      for (const data in this.countryQuartiles) {
        this.details.push({
          country: data,
          quartiles: this.countryQuartiles[data],
        });
      }
    }
  }
}
