



















import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  MiscellaneousComparisonTitleDetails,
  MonthComparisonTitleDetails,
  SummaryAnalysisOutput,
} from '@/models/swine/analysis.model';
import {
  BRONCHOPNEUMONIA_SCORE_COLORS,
  ELANCO_LOGO_IMAGE,
  LUNG_LESION_IMAGE,
  TRIANGLE_PLOT_LOGO,
} from '@/utils/analysisPdfMake';
import { convertDateToDDMMYYYYWithSlashes } from '@/utils/moment';
import AnalysisLayout from '../layout/AnalysisLayout.vue';
import BarAndLineChart from '../reports/BarAndLineChart.vue';
import BronchopneumoniaScoreAnalysisTable from './BronchopneumoniaScoreAnalysisTable.vue';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { addDecimalPointToNumbers } from '@/utils/formats';

@Component({
  components: {
    AnalysisLayout,
    BarAndLineChart,
    BronchopneumoniaScoreAnalysisTable,
  },
})
export default class BronchopneumoniaScoreComponent extends Vue {
  @Prop({})
  chartData!: Chart.ChartData;

  @Prop({})
  options!: Chart.ChartOptions;

  @Prop({})
  analysisResponse!: SummaryAnalysisOutput;

  @Prop({})
  comparisonTitle!: MiscellaneousComparisonTitleDetails &
    MonthComparisonTitleDetails;

  @Prop({ required: true })
  isCurrentRoleViewer!: boolean;

  removeNumberOfLungsExamined = false;

  updateRemoveNumberOfLungsExamined(value: boolean) {
    this.removeNumberOfLungsExamined = value;
  }

  generateChartImage() {
    const currentDocument: HTMLCanvasElement | null = document.getElementById(
      'line-chart',
    ) as HTMLCanvasElement;
    if (currentDocument) {
      return currentDocument.toDataURL();
    }
    return '';
  }

  generatePDFBodyTitle() {
    let generatedTitle = `Comparison of ${this.comparisonTitle.selectedOption} by ${this.comparisonTitle.selectedComparison} from ${this.comparisonTitle.startDate} and ${this.comparisonTitle.endDate} `;
    if (this.comparisonTitle.queries) {
      generatedTitle += `between `;
      this.comparisonTitle.queries.forEach((detail, index) => {
        if (index < this.comparisonTitle.queries.length - 1) {
          generatedTitle += `${detail}, `;
        } else {
          generatedTitle += `and ${detail}.`;
        }
      });
    }

    if (this.comparisonTitle.farmUnitCodeName) {
      generatedTitle += `on ${this.comparisonTitle.farmUnitCodeName}.`;
    }

    return generatedTitle;
  }

  generatePDFTableHeader() {
    return [
      {
        text: 'Lung Classification',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        colSpan: 2,
        margin: [12, 29, 0, 16],
      },
      {},
      {
        text: '',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
      },
      {
        text: 'Normal Lung',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        colSpan: 2,
        alignment: `center`,
        margin: [0, 29, 0, 16],
      },
      {},
      {
        text: 'Pneumonia',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        colSpan: 3,
        alignment: `center`,
        margin: [0, 29, 0, 16],
      },
      {},
      {},
      {
        text: 'Severe Pneumonia',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        colSpan: 2,
        alignment: `center`,
        margin: [0, 29, 0, 16],
      },
      {},
      {
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
        border: [false, false, false, true],
        alignment: `center`,
        table: {
          body: [
            [
              {
                svg: TRIANGLE_PLOT_LOGO,
                alignment: `center`,
                border: [false, false, false, false],
                fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
                color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
            [
              {
                text: 'Pneumonia Prevalence(%)',
                fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
                color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
                fontSize: 9,
                border: [false, false, false, false],
                bold: true,
                alignment: `center`,
              },
            ],
          ],
        },
      },
    ];
  }

  generatePDFTableCategories() {
    return [
      {
        text: 'Category',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        colSpan: 2,
        margin: [12, 15, 0, 0],
      },
      {},
      {
        text: 'No. of Lungs Examined',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '0%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '1-10%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN_DARK.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN_DARK.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '11-20%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '21-30%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '31-40%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_DARK.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_DARK.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '41-50%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '> 50%',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_DARK.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_DARK.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
      },
      {
        text: '',
        fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
        color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
      },
    ];
  }

  generatePDFTableContent(): TableCell[][] {
    if (this.analysisResponse) {
      const tableData = [];
      if (this.analysisResponse.farmUnits) {
        tableData.push(...this.analysisResponse.farmUnits);
      }
      if (this.analysisResponse.industryAverages) {
        tableData.push(...this.analysisResponse.industryAverages);
      }
      if (this.analysisResponse.individualReports) {
        tableData.push(...this.analysisResponse.individualReports);
      }

      const pdfTableDetails: TableCell[][] = [];
      tableData.forEach((data, index) => {
        if (data.pneumoniaPercentageDict) {
          pdfTableDetails.push([
            {
              text: index === 0 ? `% of Lung` : ``,
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
            },
            {
              text: data.identifier,
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.LIGHT_GREY.TEXT,
              fontSize: 9,
              border: [true, false, false, true],
              bold: true,
            },
            {
              text: data.numberOfLungsExaminedForBronchopneumoniaScore,
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.DARK_GREY.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`0`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`1`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN_DARK.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.GREEN_DARK.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`2`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_LIGHT.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`3`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`4`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_DARK.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.YELLOW_DARK.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`5`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: addDecimalPointToNumbers(data.pneumoniaPercentageDict[`6`]),
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_DARK.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_DARK.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
            {
              text: data.pneumoniaPrevalence,
              fillColor: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.BACKGROUND,
              color: BRONCHOPNEUMONIA_SCORE_COLORS.RED_LIGHT.TEXT,
              fontSize: 9,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
            },
          ]);
        }
      });
      return pdfTableDetails;
    }
    return [];
  }

  generatePdfTable(): TableCell[][] {
    return [
      [...this.generatePDFTableHeader()],
      [...this.generatePDFTableCategories()],
      ...this.generatePDFTableContent(),
    ];
  }

  generatePDF() {
    try {
      const imgElancoLogo = ELANCO_LOGO_IMAGE;
      const imgLungLesion = LUNG_LESION_IMAGE;
      const headerTitle = 'Lung Lesion Scoring Analysis';
      pdfMake.tableLayouts = {
        headerLayout: {
          hLineColor: function () {
            return '#dbdbdb';
          },
          vLineColor: function () {
            return '';
          },
          paddingLeft: function () {
            return 0;
          },
          paddingRight: function () {
            return 0;
          },
          paddingTop: function () {
            return 0;
          },
          paddingBottom: function () {
            return 0;
          },
        },
        tableLayout: {
          hLineColor: function () {
            return '#dbdbdb';
          },
          vLineColor: function () {
            return '#dbdbdb';
          },
        },
      };

      const docDefinition: TDocumentDefinitions = {
        pageOrientation: 'landscape',
        content: [
          {
            table: {
              widths: ['auto', 'auto', '*'],
              body: [
                [
                  {
                    svg: imgElancoLogo,
                    width: 100,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    svg: imgLungLesion,
                    width: 50,
                    margin: [120, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: headerTitle,
                    color: '#333',
                    bold: true,
                    fontSize: 20,
                    margin: [17, 17, 0, 17],
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'headerLayout',
          },
          {
            text: this.generatePDFBodyTitle(),
            bold: true,
            fontSize: 14,
            margin: [0, 30, 0, 30],
          },
          {
            columns: [
              {
                width: 741,
                image: this.generateChartImage(),
              },
            ],
            pageBreak: 'after',
          },
          {
            table: {
              widths: ['auto', 'auto', '*'],
              body: [
                [
                  {
                    svg: imgElancoLogo,
                    width: 100,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    svg: imgLungLesion,
                    width: 50,
                    margin: [120, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: headerTitle,
                    color: '#333',
                    bold: true,
                    fontSize: 20,
                    margin: [17, 17, 0, 17],
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'headerLayout',
          },
          {
            text: this.generatePDFBodyTitle(),
            bold: true,
            fontSize: 14,
            margin: [0, 30, 0, 30],
          },
          {
            table: {
              widths: [50, 50, 50, 60, 60, 60, 60, 60, 60, 60, 120],
              body: this.generatePdfTable(),
            },
            layout: 'tableLayout',
          },
        ],
        footer: {
          columns: [
            {
              text: `Exported on ${convertDateToDDMMYYYYWithSlashes(
                new Date(),
              )}`,
              fontSize: 10,
              color: '#8e8e8e',
              margin: [30, 0, 0, 0],
            },
          ],
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download('lung-lesion-scoring-analysis.pdf');
    } catch (error) {
      console.error(error);
    }
  }
}
