var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('div',{staticClass:"head"},[_c('h1',{staticClass:"bold"},[_vm._v("Analysis")]),_c('div',{staticClass:"dropdown__container"},[_c('h3',{staticClass:"comparison-of"},[_vm._v("Comparison of")]),_c('AnalysisDropdown',{style:("z-index: 120"),attrs:{"options":_vm.analysisSectionOptions},on:{"select":_vm.getComparisonOptions}}),_c('h3',{staticClass:"by"},[_vm._v("by")]),_c('AnalysisDropdown',{style:("z-index: 120"),attrs:{"disabled":_vm.analysisSectionComparisons.length < 1,"options":_vm.analysisSectionComparisons},on:{"select":_vm.setComparisonOptions}})],1),(
        _vm.analysisSectionComparisonOption &&
        _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
      )?_c('MiscellanenousAnalysis',{attrs:{"resetQueryFilters":_vm.resetQueryFilters,"resetDateFilters":_vm.resetDateFilters},on:{"generate":_vm.validateMiscellaneousQueries,"selectStartDate":_vm.setStartDate,"selectEndDate":_vm.setEndDate,"reset":_vm.resetComplete,"resetDates":_vm.resetDatesComplete}}):_vm._e(),(
        _vm.analysisSectionComparisonOption &&
        _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MONTH
      )?_c('MonthAnalysis',{attrs:{"resetQueryFilters":_vm.resetQueryFilters,"resetDateFilters":_vm.resetDateFilters},on:{"generate":_vm.validateMonthQuery,"selectStartDate":_vm.setStartDate,"selectEndDate":_vm.setEndDate,"reset":_vm.resetComplete,"resetDates":_vm.resetDatesComplete}}):_vm._e(),_c('div',{staticClass:"button_container"},[_c('button',{staticClass:"elanco primary analysis_button",attrs:{"disabled":_vm.isButtonDisabled === true || _vm.startDate === null || _vm.endDate === null},on:{"click":_vm.generateAnalysis}},[_vm._v(" Generate ")]),(_vm.analysisSectionComparisonOption)?_c('button',{staticClass:"elanco primary-inverted clear-filter_button",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear All Filters ")]):_vm._e()])],1),_c('div',{staticClass:"body"},[(_vm.showPlaceholder)?_c('div',{staticClass:"placeholder-container"},[_c('img',{attrs:{"src":require("@/assets/img-lung_leson.svg"),"alt":"Lung Lesion"}}),_c('span',[_vm._v("Select filters to start ")])]):_vm._e(),(!_vm.showPlaceholder && _vm.analysisResponse)?_c('div',[(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value ===
            _vm.analysisSection.LUNG_LESION_SCORE_SUMMARY
        )?_c('LungLesionScoreAnalysisComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isMiscellaneous":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS,"isCurrentRoleViewer":_vm.isCurrentRoleViewer},on:{"changeChart":_vm.changeGraphType}}):_vm._e(),(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value ===
            _vm.analysisSection.APP_INDEX_AND_PREVALENCE
        )?_c('AppIndexAndPrevalenceComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isCurrentRoleViewer":_vm.isCurrentRoleViewer}}):_vm._e(),(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value ===
            _vm.analysisSection.PNEUMONIA_INDEX_AND_PREVALENCE
        )?_c('PneumoniaIndexAndPrevalenceComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isCurrentRoleViewer":_vm.isCurrentRoleViewer}}):_vm._e(),(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value === _vm.analysisSection.PLEURISY
        )?_c('PleurisyComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isCurrentRoleViewer":_vm.isCurrentRoleViewer}}):_vm._e(),(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value ===
            _vm.analysisSection.ABSCESS_SCARRING_PERICARDITIS
        )?_c('AbscessScarringPericarditisComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isMiscellaneous":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS,"isCurrentRoleViewer":_vm.isCurrentRoleViewer},on:{"changeChart":_vm.changeGraphType}}):_vm._e(),(
          _vm.analysisSectionSelectedOption &&
          _vm.analysisSectionSelectedOption.value === _vm.analysisSection.BRONCHOPNEUMONIA_SCORE
        )?_c('BronchopneumoniaScoreComponent',{attrs:{"chartData":_vm.chartData,"options":_vm.options,"analysisResponse":_vm.analysisResponse,"comparisonTitle":_vm.analysisSectionComparisonOption &&
          _vm.analysisSectionComparisonOption.value === _vm.analysisCriteria.MISCELLANEOUS
            ? _vm.miscellaneousComparisonTitle
            : _vm.monthComparisonTitle,"isCurrentRoleViewer":_vm.isCurrentRoleViewer}}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }