




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppIndexObject } from '@/models/swine/report/report.model';
@Component({})
export default class AppIndexAndPrevalenceAnalysisTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  data!: any;

  @Prop({
    type: Boolean,
    default: false,
  })
  removeNumberOfLungsExamined!: boolean;

  tableData: any[] | null = null;

  mounted() {
    if (this.data) {
      this.tableData = [];
      if (this.data.farmUnits) {
        this.tableData.push(...this.data.farmUnits);
      }
      if (this.data.industryAverages) {
        this.tableData.push(...this.data.industryAverages);
      }
      if (this.data.individualReports) {
        this.tableData.push(...this.data.individualReports);
      }
    }
  }

  generateAppIndexStyle(details: AppIndexObject) {
    return `background-color:${details.appIndexBackgroundColor}; color:${details.appIndexColor};`;
  }
}
