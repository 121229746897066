






















import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  MiscellaneousComparisonTitleDetails,
  MonthComparisonTitleDetails,
  SummaryAnalysisOutput,
} from '@/models/swine/analysis.model';
import {
  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS,
  CROSS_PLOT_LOGO,
  ELANCO_LOGO_IMAGE,
  LUNG_LESION_IMAGE,
  TRIANGLE_PLOT_LOGO,
  DIAMOND_PLOT_LOGO,
  SQUARE_PLOT_LOGO,
  PNEUMONIA_INDEX_INTERPRETATION_LOGO,
} from '@/utils/analysisPdfMake';
import { convertDateToDDMMYYYYWithSlashes } from '@/utils/moment';
import AnalysisLayout from '../layout/AnalysisLayout.vue';
import BarAndLineChart from '../reports/BarAndLineChart.vue';
import PneumoniaIndexInterpretation from '../reports/PneumoniaIndexInterpretation.vue';
import PneumoniaIndexAndPrevalenceAnalysisTable from './PneumoniaIndexAndPrevalenceAnalysisTable.vue';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({
  components: {
    AnalysisLayout,
    BarAndLineChart,
    PneumoniaIndexInterpretation,
    PneumoniaIndexAndPrevalenceAnalysisTable,
  },
})
export default class PnuemoniaIndexAndPrevalenceComponent extends Vue {
  @Prop({})
  chartData!: Chart.ChartData;

  @Prop({})
  options!: Chart.ChartOptions;

  @Prop({})
  analysisResponse!: SummaryAnalysisOutput;

  @Prop({})
  comparisonTitle!: MiscellaneousComparisonTitleDetails &
    MonthComparisonTitleDetails;

  @Prop({ required: true })
  isCurrentRoleViewer!: boolean;

  removeNumberOfLungsExamined = false;

  updateRemoveNumberOfLungsExamined(value: boolean) {
    this.removeNumberOfLungsExamined = value;
  }

  generateChartImage() {
    const currentDocument: HTMLCanvasElement | null = document.getElementById(
      'line-chart',
    ) as HTMLCanvasElement;
    if (currentDocument) {
      return currentDocument.toDataURL();
    }
    return '';
  }

  generatePDFBodyTitle() {
    let generatedTitle = `Comparison of ${this.comparisonTitle.selectedOption} by ${this.comparisonTitle.selectedComparison} from ${this.comparisonTitle.startDate} and ${this.comparisonTitle.endDate} `;
    if (this.comparisonTitle.queries) {
      generatedTitle += `between `;
      this.comparisonTitle.queries.forEach((detail, index) => {
        if (index < this.comparisonTitle.queries.length - 1) {
          generatedTitle += `${detail}, `;
        } else {
          generatedTitle += `and ${detail}.`;
        }
      });
    }

    if (this.comparisonTitle.farmUnitCodeName) {
      generatedTitle += `on ${this.comparisonTitle.farmUnitCodeName}.`;
    }

    return generatedTitle;
  }

  generatePDFTableHeader() {
    return [
      {
        text: '',
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
        marginTop: 15,
      },
      {
        text: 'No. of Lungs Examined',
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.DARK_GREY.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.DARK_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
        marginTop: 15,
      },
      {
        text: 'Pneumonia Index',
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.TEXT,
        fontSize: 9,
        border: [false, false, false, true],
        bold: true,
        alignment: `center`,
        marginTop: 15,
      },
      {
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT,
        border: [false, false, false, true],
        alignment: `center`,
        table: {
          body: [
            [
              {
                svg: TRIANGLE_PLOT_LOGO,
                alignment: `center`,
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
            [
              {
                alignment: `center`,
                text: 'Pneumonia Prevalence (%)',
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
          ],
        },
      },
      {
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.TEXT,
        border: [false, false, false, true],
        alignment: `center`,
        table: {
          body: [
            [
              {
                svg: DIAMOND_PLOT_LOGO,
                alignment: `center`,
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
            [
              {
                alignment: `center`,
                text: 'Bronchopneumonia Prevalence (Mild to Severe) %',
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
          ],
        },
      },
      {
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.TEXT,
        border: [false, false, false, true],
        alignment: `center`,
        table: {
          body: [
            [
              {
                svg: SQUARE_PLOT_LOGO,
                alignment: `center`,
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
            [
              {
                alignment: `center`,
                text: 'Avg. % of Affected Surface out of Lungs',
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
          ],
        },
      },
      {
        fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.BACKGROUND,
        color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.TEXT,
        border: [false, false, false, true],
        alignment: `center`,
        table: {
          body: [
            [
              {
                svg: CROSS_PLOT_LOGO,
                alignment: `center`,
                border: [false, false, false, false],
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.TEXT,
                fontSize: 9,
                bold: true,
              },
            ],
            [
              {
                text:
                  'Avg.% of Affected Surface out of Lungs with Active Pneumonia',
                fillColor:
                  PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.BACKGROUND,
                color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.TEXT,
                fontSize: 9,
                border: [false, false, false, false],
                bold: true,
                alignment: `center`,
              },
            ],
          ],
        },
      },
    ];
  }

  generatePneumoniaIndexColors(value: number) {
    if (value < 0.5) {
      return {
        BACKGROUND: '#D6FFA4',
        TEXT: '#6c9d31',
      };
    }
    if (value >= 0.56 && value <= 0.89) {
      return {
        BACKGROUND: '#FFF084',
        TEXT: '#745E05',
      };
    }
    if (value >= 0.9) {
      return {
        BACKGROUND: '#FFEAEA',
        TEXT: '#FF4C4B',
      };
    }

    return {
      BACKGROUND: '#FFFFFF',
      TEXT: '#333',
    };
  }

  generatePDFTableContent(): TableCell[][] {
    if (this.analysisResponse) {
      const tableData = [];
      if (this.analysisResponse.farmUnits) {
        tableData.push(...this.analysisResponse.farmUnits);
      }
      if (this.analysisResponse.industryAverages) {
        tableData.push(...this.analysisResponse.industryAverages);
      }
      if (this.analysisResponse.individualReports) {
        tableData.push(...this.analysisResponse.individualReports);
      }

      const pdfTableDetails = tableData.map((data) => {
        if (data.pneumoniaIndex !== null) {
          const pneumoniaIndexColors = this.generatePneumoniaIndexColors(
            data.pneumoniaIndex as number,
          );
          return [
            {
              text: data.identifier,
              fillColor:
                PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.LIGHT_GREY.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              margin: [3, 3, 0, 3],
            },
            {
              text: data.numberOfLungsExaminedForBronchopneumoniaScore,
              fillColor:
                PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.DARK_GREY.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.DARK_GREY.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
            {
              text: data.pneumoniaIndex,
              fillColor: pneumoniaIndexColors.BACKGROUND,
              color: pneumoniaIndexColors.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
            {
              text: data.pneumoniaPrevalence,
              fillColor:
                PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
            {
              text: data.bronchopneumoniaPrevalence
                ? addDecimalPointToNumbers(data.bronchopneumoniaPrevalence)
                : addDecimalPointToNumbers(0),
              fillColor:
                PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.PURPLE.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
            {
              text: data.averageAffectedSurfaceOutOfLungs
                ? addDecimalPointToNumbers(
                    data.averageAffectedSurfaceOutOfLungs,
                  )
                : addDecimalPointToNumbers(0),
              fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.AQUA.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
            {
              text: data.averageAffectedSurfaceOutOfLungsWithActivePneumonia
                ? addDecimalPointToNumbers(
                    data.averageAffectedSurfaceOutOfLungsWithActivePneumonia,
                  )
                : addDecimalPointToNumbers(0),
              fillColor: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.BACKGROUND,
              color: PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.WHITE.TEXT,
              fontSize: 10,
              border: [false, false, false, true],
              bold: true,
              alignment: `center`,
              margin: [0, 3, 0, 3],
            },
          ];
        }
        return [];
      });
      return pdfTableDetails;
    }
    return [[]];
  }

  generatePdfTable() {
    const pdfTable: TableCell[][] = [this.generatePDFTableHeader()];
    const details = this.generatePDFTableContent();

    if (details) {
      details.forEach((item) => {
        if (item) {
          pdfTable.push(item);
        }
      });
    }
    return pdfTable;
  }

  generatePDF() {
    try {
      const imgElancoLogo = ELANCO_LOGO_IMAGE;
      const imgLungLesion = LUNG_LESION_IMAGE;
      const headerTitle = 'Lung Lesion Scoring Analysis';
      let vLineColorCounter = 0;
      pdfMake.tableLayouts = {
        headerLayout: {
          hLineColor: function () {
            return '#dbdbdb';
          },
          vLineColor: function () {
            return '';
          },
          paddingLeft: function () {
            return 0;
          },
          paddingRight: function () {
            return 0;
          },
          paddingTop: function () {
            return 0;
          },
          paddingBottom: function () {
            return 0;
          },
        },
        tableLayout: {
          hLineColor: function () {
            return '#dbdbdb';
          },
          vLineColor: function () {
            return '';
          },
        },
        interpretationLayout: {
          hLineColor: function (i) {
            let color = '';
            switch (i) {
              case 0: {
                color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN.TEXT;
                break;
              }
              case 1: {
                color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW.TEXT;
                break;
              }
              case 2: {
                color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT;
                break;
              }
              case 3: {
                color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT;
                break;
              }
              default: {
                color = '';
                break;
              }
            }
            return color;
          },
          vLineColor: function (i) {
            let color = ``;
            if (vLineColorCounter === 0) {
              color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN.TEXT;
            }

            if (vLineColorCounter === 1) {
              color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW.TEXT;
            }

            if (vLineColorCounter === 2) {
              color = PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT.TEXT;
            }

            if (i === 2) {
              vLineColorCounter++;
            }

            return color;
          },
        },
      };

      const docDefinition: TDocumentDefinitions = {
        pageOrientation: 'landscape',
        content: [
          {
            table: {
              widths: ['auto', 'auto', '*'],
              body: [
                [
                  {
                    svg: imgElancoLogo,
                    width: 100,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    svg: imgLungLesion,
                    width: 50,
                    margin: [120, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: headerTitle,
                    color: '#333',
                    bold: true,
                    fontSize: 20,
                    margin: [17, 17, 0, 17],
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'headerLayout',
          },
          {
            text: this.generatePDFBodyTitle(),
            bold: true,
            fontSize: 14,
            margin: [0, 30, 0, 30],
          },
          {
            margin: [76, 20, 0, 0],
            canvas: [
              {
                type: `rect`,
                w: 630,
                h: 100,
                r: 10,
                x: 0,
                y: 0,
                lineColor: `#E3E3E3`,
              },
            ],
          },
          {
            absolutePosition: this.comparisonTitle.farmUnitCodeName
              ? {
                  x: 124,
                  y: 200,
                }
              : {
                  x: 124,
                  y: 205,
                },
            pageBreak: 'after',
            alignment: 'center',
            margin: [20, 15, 20, 15],
            table: {
              body: [
                [
                  {
                    svg: PNEUMONIA_INDEX_INTERPRETATION_LOGO,
                    width: 50,
                    height: 50,
                    border: [false, false, false, false],
                    alignment: `center`,
                    marginTop: 10,
                    marginBottom: 10,
                  },
                  {
                    text: `Pneumonia Index Interpretation`,
                    fontSize: 16,
                    width: 164,
                    bold: true,
                    height: 40,
                    border: [false, false, false, false],
                    margin: [12, 30, 40, 30],
                  },
                  {
                    border: [false, false, false, false],
                    table: {
                      body: [
                        [
                          {
                            text: `0.1 to 0.55`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN.TEXT,
                          },
                          {
                            text: `Imperceptible Pneumonia`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.GREEN.TEXT,
                          },
                        ],
                        [
                          {
                            text: `0.56 to 0.89`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW.TEXT,
                          },
                          {
                            text: `Presence of Pneumonia`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.YELLOW.TEXT,
                          },
                        ],
                        [
                          {
                            text: `> 0.9`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT
                                .TEXT,
                          },
                          {
                            text: `Serious Pneumonia`,
                            fontSize: 14,
                            fillColor:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT
                                .BACKGROUND,
                            color:
                              PNEUMONIA_INDEX_AND_PREVALENCE_COLORS.RED_LIGHT
                                .TEXT,
                          },
                        ],
                      ],
                    },
                    layout: 'interpretationLayout',
                  },
                ],
              ],
            },
          },
          {
            table: {
              widths: ['auto', 'auto', '*'],
              body: [
                [
                  {
                    svg: imgElancoLogo,
                    width: 100,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    svg: imgLungLesion,
                    width: 50,
                    margin: [120, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: headerTitle,
                    color: '#333',
                    bold: true,
                    fontSize: 20,
                    margin: [17, 17, 0, 17],
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'headerLayout',
          },
          {
            text: this.generatePDFBodyTitle(),
            bold: true,
            fontSize: 14,
            margin: [0, 30, 0, 30],
          },
          {
            columns: [
              {
                width: 741,
                image: this.generateChartImage(),
              },
            ],
            pageBreak: 'after',
          },
          {
            table: {
              widths: ['auto', 'auto', '*'],
              body: [
                [
                  {
                    svg: imgElancoLogo,
                    width: 100,
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    svg: imgLungLesion,
                    width: 50,
                    margin: [120, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: headerTitle,
                    color: '#333',
                    bold: true,
                    fontSize: 20,
                    margin: [17, 17, 0, 17],
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: 'headerLayout',
          },
          {
            text: this.generatePDFBodyTitle(),
            bold: true,
            fontSize: 14,
            margin: [0, 30, 0, 30],
          },
          {
            table: {
              widths: [112, 80, 60, 80, 150, 120, '*'],
              body: this.generatePdfTable(),
            },
            layout: 'tableLayout',
          },
        ],
        footer: {
          columns: [
            {
              text: `Exported on ${convertDateToDDMMYYYYWithSlashes(
                new Date(),
              )}`,
              fontSize: 10,
              color: '#8e8e8e',
              margin: [30, 0, 0, 0],
            },
          ],
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download('lung-lesion-scoring-analysis.pdf');
    } catch (error) {
      console.error(error);
    }
  }
}
