




























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '../DropdownAnalysisTemplate.vue';
import { AnalysisStores } from '@/store/swine/analysis';
import {
  AnalysisDropdownSelectionQueries,
  AnalysisParams,
} from '@/models/swine/analysis.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { convertDateToYYYYMMDDwithDashesAndUTC } from '@/utils/moment';
@Component({
  components: {
    Dropdown,
  },
})
export default class FarmUnitAnalysisMultiDropdown extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  queries!: { [key: string]: string | null };

  @Prop({
    type: Number,
    default: null,
  })
  selectedId!: number;

  @Prop({})
  resetQuery!: { [key: number]: boolean };

  @Prop({
    type: Boolean,
    default: true,
  })
  showDeleteButton!: { [key: number]: boolean };

  @Prop({
    type: Date,
    default: null,
  })
  startDate!: Date;

  @Prop({
    type: Date,
    default: null,
  })
  endDate!: Date;

  // Reason why we can do this is because we know what is the logical order
  countries: DropdownOption[] | null = null;
  regions: DropdownOption[] | null = null;
  states: DropdownOption[] | null = null;
  clients: DropdownOption[] | null = null;
  farms: DropdownOption[] | null = null;
  farmUnits: DropdownOption[] | null = null;
  store = AnalysisStores.detail;
  params: AnalysisDropdownSelectionQueries = {
    specifiedId: null,
    countryId: null,
    regionId: null,
    stateId: null,
    clientId: null,
    farmId: null,
    farmUnitId: null,
    startDate: null,
    endDate: null,
  };
  isRegionDisabled = false;
  selectedCountry = ``;
  selectedRegion = ``;
  selectedState = ``;
  selectedClient = ``;
  selectedFarm = ``;
  selectedFarmUnit = ``;
  selectedCountryText = `Country`;
  selectedRegionText = `Region`;
  selectedStateText = `State/Province`;
  selectedClientText = `Client`;
  selectedFarmText = `Farm`;
  selectedFarmUnitText = `Farm Unit`;
  currentDataIndex = -1;

  @Watch('resetQuery', { deep: true, immediate: true })
  resetDropdowns() {
    if (this.resetQuery && this.resetQuery[this.selectedId]) {
      for (const key in this.queries) {
        if (key !== 'comparison_type' && key !== 'query_id') {
          this.queries[key] = null;
        }
      }
      this.selectedCountryText = `Country`;
      this.selectedRegionText = `Region`;
      this.selectedStateText = `State/Province`;
      this.selectedClientText = `Client`;
      this.selectedFarmText = `Farm`;
      this.selectedFarmUnitText = `Farm Unit`;
      this.resetSelectedQuery();
      this.resetComplete();
    }
  }

  @Emit('updateQuery')
  resetSelectedQuery() {
    return this.queries;
  }

  @Emit('reset')
  resetComplete() {
    return this.selectedId;
  }

  mounted() {
    this.queries[`comparison_type`] = 'farm_unit';
    this.params.specifiedId = this.selectedId;
    this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
      this.startDate
    );
    this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(this.endDate);
    this.resetDropdown(`all`);
    this.selectedCountryText = `Country`;
    this.selectedRegionText = `Region`;
    this.selectedStateText = `State/Province`;
    this.selectedClientText = `Client`;
    this.selectedFarmText = `Farm`;
    this.selectedFarmUnitText = `Farm Unit`;
    this.updateDropdownStates();
  }

  @Watch('startDate')
  @Watch('endDate')
  updateDropdownStates() {
    if (this.startDate && this.endDate) {
      this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
        this.startDate
      );
      this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(this.endDate);
      const searchParams: AnalysisParams = {
        queries: this.params,
      };
      if (this.store.countries.length === 0) {
        this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
          this.startDate
        );
        this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(
          this.endDate
        );
        this.store.getAllCountries(searchParams);
      }

      if (this.store.countries.length > 0) {
        this.updateCountryOptions();
        this.updateRegionOptions();
        this.updateStateOptions();
        this.updateClientOptions();
        this.updateFarmOptions();
        this.updateFarmUnitOptions();
      }
    }
  }

  disableDropdown(key: string) {
    // Any queries that is empty or with the value all should be disabled
    if (!this.queries[key] || this.queries[key] === 'all') {
      return true;
    }
    return false;
  }

  resetDropdown(key: string) {
    switch (key) {
      case 'countries': {
        this.selectedCountryText = `Country`;
        this.countries = [
          {
            text: `Country`,
            value: null,
            uniqueKey: `country_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'regions': {
        this.selectedRegionText = `Region`;
        this.regions = [
          {
            text: `Region`,
            value: null,
            uniqueKey: `region_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'states': {
        this.selectedStateText = `State/Province`;
        this.states = [
          {
            text: `State/Province`,
            value: null,
            uniqueKey: `state_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'clients': {
        this.selectedClientText = `Client`;
        this.clients = [
          {
            text: `Client`,
            value: null,
            uniqueKey: `client_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'farms': {
        this.selectedFarmText = `Farm`;
        this.farms = [
          {
            text: `Farm`,
            value: null,
            uniqueKey: `farm_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'farmUnits': {
        this.selectedFarmUnitText = `Farm Unit`;
        this.farmUnits = [
          {
            text: `Farm Unit`,
            value: null,
            uniqueKey: `farm_unit_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      case 'all': {
        this.countries = [
          {
            text: `Country`,
            value: null,
            uniqueKey: `country_${this.params.specifiedId}`,
          },
        ];
        this.regions = [
          {
            text: `Region`,
            value: null,
            uniqueKey: `region_${this.params.specifiedId}`,
          },
        ];
        this.states = [
          {
            text: `State/Province`,
            value: null,
            uniqueKey: `state_${this.params.specifiedId}`,
          },
        ];
        this.clients = [
          {
            text: `Client`,
            value: null,
            uniqueKey: `client_${this.params.specifiedId}`,
          },
        ];
        this.farms = [
          {
            text: `Farm`,
            value: null,
            uniqueKey: `farm_${this.params.specifiedId}`,
          },
        ];
        this.farmUnits = [
          {
            text: `Farm Unit`,
            value: null,
            uniqueKey: `farm_unit_${this.params.specifiedId}`,
          },
        ];
        break;
      }
      default: {
        break;
      }
    }
  }

  @Watch(`store.countries`)
  updateCountryOptions() {
    if (this.store.countries.length >= 0) {
      this.countries = this.store.countries;
    }
  }

  @Watch(`store.regions`)
  updateRegionOptions() {
    if (this.store.regions.length >= 0) {
      const index = this.store.regions.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.regions[index]) {
        this.regions = this.store.regions[index].data;
        // Explicit check for region
        const notAvailableRegionIndex = this.store.regions[
          index
        ].data.findIndex((details) => {
          return details.text === '00';
        });
        if (notAvailableRegionIndex > -1 && this.queries) {
          this.isRegionDisabled = true;
          this.params.regionId =
            this.store.regions[index].data[notAvailableRegionIndex].value;
          this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
            this.startDate
          );
          this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(
            this.endDate
          );
          this.queries[`region`] = this.params.regionId as string;
          if (!this.store.regions[this.currentDataIndex]) {
            const searchParams: AnalysisParams = {
              isAllAvailable: true,
              queries: this.params,
            };
            this.store.getAllStatesByRegionId(searchParams);
          }
        } else {
          this.isRegionDisabled = false;
        }
      }
    }
  }

  @Watch(`store.states`)
  updateStateOptions() {
    if (this.store.states.length >= 0) {
      const index = this.store.states.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.states[index]) {
        this.states = this.store.states[index].data;
      }
    }
  }

  @Watch(`store.clients`)
  updateClientOptions() {
    if (this.store.clients.length >= 0) {
      const index = this.store.clients.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.clients[index]) {
        this.clients = this.store.clients[index].data;
      }
    }
  }

  @Watch(`store.farms`)
  updateFarmOptions() {
    if (this.store.farms.length >= 0) {
      const index = this.store.farms.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.farms[index]) {
        this.farms = this.store.farms[index].data;
      }
    }
  }

  @Watch(`store.farmUnits`)
  updateFarmUnitOptions() {
    if (this.store.farmUnits.length > 0) {
      const index = this.store.farmUnits.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.farmUnits[index]) {
        this.farmUnits = this.store.farmUnits[index].data;
      }
    }
  }

  @Emit(`updateQuery`)
  updateCountryId(dropdown: DropdownOption) {
    if (dropdown && dropdown.value && dropdown.value.length > 0) {
      this.queries[`country`] = dropdown.value;
    }
    if (this.queries[`query_id`] === String(this.selectedId)) {
      // Reset query based on selection
      this.selectedRegionText = `Region`;
      this.selectedStateText = `State/Province`;
      this.selectedClientText = `Client`;
      this.selectedFarmText = `Farm`;
      this.selectedFarmUnitText = `Farm Unit`;
      this.queries['region'] = null;
      this.queries['state'] = null;
      this.queries['client'] = null;
      this.queries['farm'] = null;
      this.queries['farm_unit'] = null;
    }
    this.params.countryId = dropdown.value;
    this.selectedCountryText = dropdown.text;
    if (
      this.params.countryId !== 'all' &&
      !this.store.regions[this.currentDataIndex]
    ) {
      const searchParams: AnalysisParams = {
        isAllAvailable: true,
        queries: this.params,
      };
      this.store.getAllRegionByCountryId(searchParams);
    }
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit(`updateQuery`)
  updateRegionId(dropdown: DropdownOption) {
    if (dropdown.value.length > 0) {
      this.queries[`region`] = dropdown.value;
    }
    if (this.queries['query_id'] === String(this.selectedId)) {
      // Reset query based on selection
      this.selectedStateText = `State/Province`;
      this.selectedClientText = `Client`;
      this.selectedFarmText = `Farm`;
      this.selectedFarmUnitText = `Farm Unit`;
      this.queries['state'] = null;
      this.queries['client'] = null;
      this.queries['farm'] = null;
      this.queries['farm_unit'] = null;
    }
    this.params.regionId = dropdown.value;
    this.selectedRegionText = dropdown.text;
    if (
      this.params.regionId !== 'all' &&
      !this.store.states[this.currentDataIndex]
    ) {
      const searchParams: AnalysisParams = {
        isAllAvailable: true,
        queries: this.params,
      };
      this.store.getAllStatesByRegionId(searchParams);
    }
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit(`updateQuery`)
  updateStateId(dropdown: DropdownOption) {
    if (dropdown && dropdown.value && dropdown.value.length > 0) {
      this.queries[`state`] = dropdown.value;
    }
    if (this.queries[`query_id`] === String(this.selectedId)) {
      // Reset query based on selection
      this.selectedClientText = `Client`;
      this.selectedFarmText = `Farm`;
      this.selectedFarmUnitText = `Farm Unit`;
      this.queries['client'] = null;
      this.queries['farm'] = null;
      this.queries['farm_unit'] = null;
    }
    this.params.stateId = dropdown.value;
    this.selectedStateText = dropdown.text;
    if (
      this.params.stateId !== 'all' &&
      !this.store.clients[this.currentDataIndex]
    ) {
      const searchParams: AnalysisParams = {
        isAllAvailable: true,
        queries: this.params,
      };
      this.store.getAllClientsByCountryRegionStateIds(searchParams);
    }
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit(`updateQuery`)
  updateClientId(dropdown: DropdownOption) {
    if (dropdown && dropdown.value && dropdown.value.length > 0) {
      this.queries[`client`] = dropdown.value;
    }
    if (this.queries[`query_id`] === String(this.selectedId)) {
      // Reset query based on selection
      this.selectedFarmText = `Farm`;
      this.selectedFarmUnitText = `Farm Unit`;
      this.queries['farm'] = null;
      this.queries['farm_unit'] = null;
    }
    this.params.clientId = dropdown.value;
    this.selectedClientText = dropdown.text;
    if (
      this.params.clientId !== 'all' &&
      !this.store.farms[this.currentDataIndex]
    ) {
      const searchParams: AnalysisParams = {
        isAllAvailable: true,
        queries: this.params,
      };
      this.store.getAllFarmsByClientId(searchParams);
    }
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit(`updateQuery`)
  updateFarmId(dropdown: DropdownOption) {
    if (dropdown && dropdown.value && dropdown.value.length > 0) {
      this.queries[`farm`] = dropdown.value;
    }
    if (this.queries[`query_id`] === String(this.selectedId)) {
      // Reset query based on selection
      this.selectedFarmUnitText = `Farm Unit`;
      this.queries['farm_unit'] = null;
    }
    this.params.farmId = dropdown.value;
    this.selectedFarmText = dropdown.text;
    if (
      this.params.farmId !== 'all' &&
      !this.store.farmUnits[this.currentDataIndex]
    ) {
      const searchParams: AnalysisParams = {
        isAllAvailable: true,
        queries: this.params,
      };
      this.store.getAllFarmUnitsByFarmId(searchParams);
    }
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit(`updateQuery`)
  updateFarmUnitId(dropdown: DropdownOption) {
    if (dropdown && dropdown.value && dropdown.value.length > 0) {
      this.queries[`farm_unit`] = dropdown.value;
    }
    this.params.farmUnitId = dropdown.value;
    this.selectedFarmUnitText = dropdown.text;
    this.queries[`query_id`] = String(this.selectedId);
    return this.queries;
  }

  @Emit('close')
  removeDropdown() {
    return this.selectedId;
  }
}
