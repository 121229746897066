


















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {
  MiscellaneousComparisonTitleDetails,
  MonthComparisonTitleDetails,
} from '@/models/swine/analysis.model';
@Component({})
export default class AnalysisLayout extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  comparisonTitle!:
    | MiscellaneousComparisonTitleDetails
    | MonthComparisonTitleDetails;

  @Prop({
    type: String,
    default: `Hide No. of Lungs Examined`,
  })
  checkboxTitle!: string;

  @Prop({ required: true })
  isCurrentRoleViewer!: boolean;

  @Emit(`hide`)
  hideNumberOfLungs(value: boolean) {
    return value;
  }

  @Emit('generate')
  generatePdf() {
    return true;
  }
}
