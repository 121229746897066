













































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({})
export default class AnalysisDropdown extends Vue {
  isActive = false;
  selected: DropdownOption | null = null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  options!: DropdownOption[];

  @Prop()
  selectedOption!: DropdownOption | string;

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.isActive = !this.isActive;
    this.selected = data;
    return data;
  }

  @Watch('selectedOption')
  updateSelectedOption() {
    if (typeof this.selectedOption !== 'string') {
      this.selected = this.selectedOption;
      this.handleSelected(this.selected);
    } else {
      const selectedOption = this.options.filter(
        (option) => option.value === this.selectedOption,
      );
      if (selectedOption[0]) {
        this.selected = selectedOption[0];
        this.handleSelected(this.selected);
      }
    }
  }

  created() {
    if (this.selectedOption && this.selectedOption !== '') {
      this.updateSelectedOption();
    } else {
      this.initializeDefaultOption();
    }
  }

  initializeDefaultOption() {
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => {
        el.value === this.selectedOption;
      });
      if (option) {
        this.selected = option;
      } else {
        // Set a default value
        this.selected = {
          text: `Select One`,
          value: ``,
          uniqueKey: generateUniqueId('analysis'),
        };
      }
    }
  }
}
