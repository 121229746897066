























































































































































































import Page from "@/components/swine/layout/Page.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import AnalysisDropdown from "@/components/swine/dropdowns/AnalysisDropdown.vue";
import {
  AnalysisCriteriaSections,
  AnalysisDropdownSections,
  ANALYSIS_COLORS,
  ANALYSIS_DROPDOWN_SECTIONS,
  ANALYSIS_SECTION_COMPARISONS,
  Role,
  VARIABLES_TO_WORDS,
} from "@/utils/constants";
import { DropdownOption } from "@/models/swine/dropdown.model";
import MiscellanenousAnalysis from "@/components/swine/analysis/MiscellaneousAnalysis.vue";
import MonthAnalysis from "@/components/swine/analysis/MonthAnalysis.vue";
import { AnalysisStores } from "@/store/swine/analysis";
import {
  AnalysisQueries,
  MiscellaneousComparisonTitleDetails,
  MonthComparisonTitleDetails,
  SummaryAnalysis,
  SummaryAnalysisOutput,
} from "@/models/swine/analysis.model";
import moment from "moment";
import LungLesionScoreAnalysisComponent from "@/components/swine/analysis/LungLesionScoreAnalysisComponent.vue";
import AppIndexAndPrevalenceComponent from "@/components/swine/analysis/AppIndexAndPrevalenceComponent.vue";
import PneumoniaIndexAndPrevalenceComponent from "@/components/swine/analysis/PneumoniaIndexAndPrevalenceComponent.vue";
import Chart, { ChartDataSets } from "chart.js";
import PleurisyComponent from "@/components/swine/analysis/PleurisyComponent.vue";
import AbscessScarringPericarditisComponent from "@/components/swine/analysis/AbscessScarringPericarditisComponent.vue";
import BronchopneumoniaScoreComponent from "@/components/swine/analysis/BronchopneumoniaScoreComponent.vue";
import {
  ABSCESS_SCARRING_PERICARDITIS_GRAPH_ANALYSIS_CONFIG,
  APP_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG,
  BRONCHOPNEUMONIA_SCORE_ANALYSIS_GRAPH_CONFIG,
  LUNG_LESION_SCORE_SUMMARY_ANALYSIS_GRAPH_CONFIG,
  PLEURISY_GRAPH_ANALYSIS_CONFIG,
  PNEUMONIA_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG,
} from "@/utils/graphConfig";
import {
  convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp,
  convertDateToYYYYMMDDwithDashesAndUTC,
} from "@/utils/moment";
import { UserSessionStore } from "@/store/swine/user-session";
import { ProfileStores } from "@/store/swine/profile";

@Component({
  components: {
    Page,
    AnalysisDropdown,
    MiscellanenousAnalysis,
    MonthAnalysis,
    LungLesionScoreAnalysisComponent,
    AppIndexAndPrevalenceComponent,
    PneumoniaIndexAndPrevalenceComponent,
    PleurisyComponent,
    AbscessScarringPericarditisComponent,
    BronchopneumoniaScoreComponent,
  },
})
export default class Analysis extends Vue {
  analysisSectionOptions: DropdownOption[] = [];
  analysisSectionComparisons: DropdownOption[] = [];
  analysisSectionSelectedOption: DropdownOption | null = null;
  analysisSectionComparisonOption: DropdownOption | null = null;
  isButtonDisabled = true;
  startDate: Date | null = null;
  endDate: Date | null = null;
  showStartDate: Date | null = null;
  showEndDate: Date | null = null;
  showPlaceholder = true;
  miscellanenousQueries: { [key: string]: string | null }[] = [];
  monthQuery: { [key: string]: string | null } = {};
  store = AnalysisStores.detail;
  params: AnalysisQueries | null = null;
  analysisResponse: SummaryAnalysisOutput | null = null;
  chartData: Chart.ChartData | null = null;
  options: Chart.ChartOptions | null = null;
  showBarChart = true;
  resetQueryFilters = false;
  resetDateFilters = false;
  userSessionDetails = UserSessionStore.detail;
  isCurrentRoleViewer = false;
  userProfileStore = ProfileStores.profile;
  isDuplicateFarmUnitsExist = false;

  mounted() {
    this.analysisSectionOptions = ANALYSIS_DROPDOWN_SECTIONS;
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.isCurrentRoleViewer =
        this.userProfileStore.userProfile.role === Role.Viewer ? true : false;
    }
  }

  changeGraphType(value: boolean) {
    this.showBarChart = value;
    this.showGraph();
  }

  get analysisSection() {
    return AnalysisDropdownSections;
  }

  get analysisCriteria() {
    return AnalysisCriteriaSections;
  }

  getComparisonOptions(options: DropdownOption) {
    if (options && options.value) {
      this.chartData = null;
      this.options = null;
      this.analysisSectionSelectedOption = options;
      this.analysisSectionComparisons = ANALYSIS_SECTION_COMPARISONS[options.value];
      this.chartData = null;
      this.options = null;
      this.analysisResponse = null;
    }
  }

  setComparisonOptions(options: DropdownOption) {
    this.analysisSectionComparisonOption = options;
    // Reset everything
    this.showPlaceholder = true;
    this.store.replaceRegionOptions([]);
    this.store.replaceStateOptions([]);
    this.store.replaceClientOptions([]);
    this.store.replaceFarmOptions([]);
    this.store.replaceFarmUnitOptions([]);
    this.store.replaceAuditReportsOptions([]);
    this.isButtonDisabled = true;
    this.chartData = null;
    this.options = null;
    this.analysisResponse = null;
    this.isDuplicateFarmUnitsExist = false;
  }

  setStartDate(date: Date) {
    this.startDate = date;
    this.showStartDate = date;
    // Reset Dates to get latest queries
    this.miscellanenousQueries = [];
    this.monthQuery = {};
    this.resetQueryFilters = true;
  }

  setEndDate(date: Date) {
    this.endDate = date;
    this.showEndDate = date;
    // Reset Dates to get latest queries
    this.miscellanenousQueries = [];
    this.monthQuery = {};
    this.resetQueryFilters = true;
  }

  validateQuery(queryDict: { [key: string]: string | null }) {
    // Case 1: Query ID is missing from the dictionary
    if (!queryDict[`query_id`]) {
      return true;
    }

    // Case 2: Farm Unit comparison type but missing fields
    if (queryDict[`comparison_type`] === "farm_unit") {
      // Sub case 1
      if (!queryDict[`country`]) {
        return true;
      }
      // Sub case 2
      if (
        queryDict[`country`] &&
        queryDict[`country`] !== `all` &&
        !queryDict[`region`]
      ) {
        return true;
      }
      // Sub case 3
      if (queryDict[`region`] && queryDict[`region`] !== `all` && !queryDict[`state`]) {
        return true;
      }
      // Sub case 4
      if (queryDict[`state`] && queryDict[`state`] !== `all` && !queryDict[`client`]) {
        return true;
      }
      //Sub case 5
      if (queryDict[`client`] && queryDict[`client`] !== `all` && !queryDict[`farm`]) {
        return true;
      }
      //Sub case 6
      if (queryDict[`farm`] && queryDict[`farm`] !== `all` && !queryDict[`farm_unit`]) {
        return true;
      }

      return false;
    } else if (
      queryDict[`comparison_type`] === "individual_report" &&
      !queryDict[`report_id`]
    ) {
      // Case 3: Individual Report comparison type but missing report id
      return true;
    } else if (
      queryDict[`comparison_type`] === "industry_average" &&
      !queryDict[`country`]
    ) {
      // Case 4: Industry Average comparison type but missing country id
      return true;
    }
    return false;
  }

  validateMiscellaneousQueries(queriesArray: { [key: string]: string | null }[]) {
    this.isButtonDisabled = false;
    this.miscellanenousQueries = queriesArray;
    const farmUnitSet = new Set<string>();
    const individualReportSet = new Set<string>();
    const industryAverageSet = new Set<string>();
    for (let index = 0; index < queriesArray.length; index++) {
      if (!this.isButtonDisabled) {
        this.isButtonDisabled = this.validateQuery(queriesArray[index]);
        if (queriesArray[index][`comparison_type`] === `farm_unit`) {
          const farmUnitQuery = this.generateFarmUnitCode(queriesArray[index]);

          if (farmUnitSet.has(farmUnitQuery)) {
            this.isDuplicateFarmUnitsExist = true;
          }
          if (farmUnitSet.has(farmUnitQuery) || farmUnitQuery === "null") {
            this.isButtonDisabled = true;
          } else {
            farmUnitSet.add(farmUnitQuery);
          }
        } else if (queriesArray[index][`comparison_type`] === `industry_average`) {
          const industryAverageQuery = queriesArray[index][`country`];
          if (industryAverageQuery && industryAverageSet.has(industryAverageQuery)) {
            this.isButtonDisabled = true;
          } else if (industryAverageQuery) {
            industryAverageSet.add(industryAverageQuery);
          }
        } else if (queriesArray[index][`comparison_type`] === `individual_report`) {
          const individualReportQuery = queriesArray[index][`report_id`];
          if (individualReportQuery && individualReportSet.has(individualReportQuery)) {
            this.isButtonDisabled = true;
          } else if (individualReportQuery) {
            individualReportSet.add(individualReportQuery);
          }
        }
      }
    }
  }

  validateMonthQuery(query: { [key: string]: string | null }) {
    this.isButtonDisabled = true;
    this.monthQuery = query;
    this.isButtonDisabled = this.validateQuery(query);
  }

  generateFarmUnitCode(data: { [key: string]: string | null }) {
    const order = [`region`, `state`, `client`, `farm`, `farm_unit`];
    let farmUnitCode = `${data[`country`]}`;
    for (const key of order) {
      if (data[key]) {
        farmUnitCode += `-${data[key]}`;
      }
    }
    return farmUnitCode;
  }

  generateAnalysis() {
    this.chartData = null;
    this.options = null;
    this.analysisResponse = null;

    if (
      this.startDate &&
      this.endDate &&
      this.analysisSectionSelectedOption &&
      this.analysisSectionComparisonOption
    ) {
      this.params = {
        startDate: convertDateToYYYYMMDDwithDashesAndUTC(this.startDate),
        endDate: convertDateToYYYYMMDDwithDashesAndUTC(this.endDate),
        criteria: this.analysisSectionComparisonOption.value,
        comparison: this.analysisSectionSelectedOption.value,
        farmUnits: null,
        reports: null,
        industryAverages: null,
      };
    }
    if (
      this.miscellanenousQueries &&
      this.analysisSectionComparisonOption &&
      this.analysisSectionComparisonOption.value === this.analysisCriteria.MISCELLANEOUS
    ) {
      // Parse the queries
      for (let index = 0; index < this.miscellanenousQueries.length; index++) {
        if (
          this.miscellanenousQueries[index][`comparison_type`] === `farm_unit` &&
          this.params
        ) {
          // We will need to build the strings
          const farmUnitCode = this.generateFarmUnitCode(
            this.miscellanenousQueries[index]
          );
          if (!this.params.farmUnits) {
            this.params.farmUnits = farmUnitCode;
          } else {
            this.params.farmUnits += `,${farmUnitCode}`;
          }
        }

        if (
          this.miscellanenousQueries[index][`comparison_type`] === `industry_average` &&
          this.params
        ) {
          if (
            !this.params.industryAverages &&
            this.miscellanenousQueries &&
            this.miscellanenousQueries[index] &&
            this.miscellanenousQueries[index][`country`]
          ) {
            this.params.industryAverages = this.miscellanenousQueries[index][`country`];
          } else {
            this.params.industryAverages += `,${
              this.miscellanenousQueries[index][`country`]
            }`;
          }
        }

        if (
          this.miscellanenousQueries[index][`comparison_type`] === `individual_report` &&
          this.params
        ) {
          if (
            !this.params.reports &&
            this.miscellanenousQueries &&
            this.miscellanenousQueries[index] &&
            this.miscellanenousQueries[index][`report_id`]
          ) {
            this.params.reports = this.miscellanenousQueries[index][`report_id`];
          } else {
            this.params.reports += `,${this.miscellanenousQueries[index][`report_id`]}`;
          }
        }
      }
    }

    if (
      this.monthQuery &&
      this.params &&
      this.analysisSectionComparisonOption &&
      this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH
    ) {
      // We will need to build the strings
      const farmUnitCode = this.generateFarmUnitCode(this.monthQuery);
      if (!this.params.farmUnits) {
        this.params.farmUnits = farmUnitCode;
      } else {
        this.params.farmUnits += `,${farmUnitCode}`;
      }
    }
    if (this.params) {
      this.store.getAnalysis(this.params);
    }
  }

  clearAllFilters() {
    // Reset all queries
    this.startDate = null;
    this.endDate = null;
    this.miscellanenousQueries = [];
    this.monthQuery = {};
    this.resetDateFilters = true;
    this.resetQueryFilters = true;
  }

  resetDatesComplete(value: boolean) {
    this.resetDateFilters = value;
  }

  resetComplete(value: boolean) {
    this.resetQueryFilters = value;
  }

  generateLungLesionScoreSummaryDataset(
    analysisDetails: SummaryAnalysis[],
    showBarChart: boolean
  ) {
    const labels = [];
    const datasets: ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForBronchopneumoniaScore" &&
          key !== "numberOfLungsExaminedForPleuritis" &&
          key !== "numberOfLungsExaminedForAbscess" &&
          key !== "numberOfLungsExaminedForScarring" &&
          key !== "numberOfLungsExaminedForPericarditis" &&
          !datasetDict[key]
        ) {
          datasetDict[key] = [];
          colorDict[key] = [];
          borderDict[key] = [];
        }

        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `bronchopneumoniaLungs`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.BRONCHOPNEUMONIA_LUNGS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.BRONCHOPNEUMONIA_LUNGS.BORDER
            );
            break;
          }
          case `averageAffectedLungsSurfaceWithActivePneumonia`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY
                .AVERAGE_AFFECTED_LUNGS_SURFACE_WITH_ACTIVE_PNEUMONIA.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY
                .AVERAGE_AFFECTED_LUNGS_SURFACE_WITH_ACTIVE_PNEUMONIA.BORDER
            );
            break;
          }
          case `cranialPleurisy`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.CRANIAL_PLEURISY.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.CRANIAL_PLEURISY.BORDER
            );
            break;
          }
          case `dorsocaudalPleurisy`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.DORSOCAUDAL_PLEURISY.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.DORSOCAUDAL_PLEURISY.BORDER
            );
            break;
          }
          case `abscess`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.ABSCESS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.ABSCESS.BORDER
            );
            break;
          }
          case `scarring`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.SCARRING.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.SCARRING.BORDER
            );
            break;
          }
          case `pericarditis`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.PERICARDITIS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.PERICARDITIS.BORDER
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    if (showBarChart === true) {
      // After parsing the values, we can parse it to fit ChartJS
      for (const key in datasetDict) {
        datasets.push({
          label: `${VARIABLES_TO_WORDS[key]}`,
          data: datasetDict[key],
          backgroundColor: colorDict[key],
          borderColor: borderDict[key],
          borderWidth: 0.8,
          barThickness: "flex",
        });
      }
    } else if (showBarChart === false) {
      for (const key in datasetDict) {
        switch (key) {
          case `bronchopneumoniaLungs`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.BRONCHOPNEUMONIA_LUNGS
                  .BACKGROUND,
              borderColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.BRONCHOPNEUMONIA_LUNGS.BORDER,
            });
            break;
          }
          case `averageAffectedLungsSurfaceWithActivePneumonia`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY
                  .AVERAGE_AFFECTED_LUNGS_SURFACE_WITH_ACTIVE_PNEUMONIA.BACKGROUND,
              borderColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY
                  .AVERAGE_AFFECTED_LUNGS_SURFACE_WITH_ACTIVE_PNEUMONIA.BORDER,
            });
            break;
          }
          case `cranialPleurisy`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.CRANIAL_PLEURISY.BACKGROUND,
              borderColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.CRANIAL_PLEURISY.BORDER,
            });
            break;
          }
          case `dorsocaudalPleurisy`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.DORSOCAUDAL_PLEURISY.BACKGROUND,
              borderColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.DORSOCAUDAL_PLEURISY.BORDER,
            });
            break;
          }
          case `abscess`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.ABSCESS.BACKGROUND,
              borderColor: ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.ABSCESS.BORDER,
            });
            break;
          }
          case `scarring`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.SCARRING.BACKGROUND,
              borderColor: ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.SCARRING.BORDER,
            });
            break;
          }
          case `pericarditis`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.PERICARDITIS.BACKGROUND,
              borderColor: ANALYSIS_COLORS.LUNG_LESION_SCORE_SUMMARY.PERICARDITIS.BORDER,
            });
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  generateAppIndexAndPrevalenceDataset(analysisDetails: SummaryAnalysis[]) {
    const labels = [];
    const datasets: Chart.ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForPleuritis" &&
          !datasetDict[key]
        ) {
          datasetDict[key] = [];
          colorDict[key] = [];
          borderDict[key] = [];
        }
        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `appIndex`: {
            datasetDict[key].push(value.appIndex);
            colorDict[key].push(value.appIndexBackgroundColor);
            borderDict[key].push(value.appIndexColor);
            break;
          }
          case `appPleurisyPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.APP_PLEURISY_PREVALENCE.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.APP_PLEURISY_PREVALENCE.BORDER
            );
            break;
          }
          case `pleurisyPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.PLEURISY_PREVALENCE.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.PLEURISY_PREVALENCE.BORDER
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }

    // After parsing the values, we can parse it to fit ChartJS
    for (const key in datasetDict) {
      switch (key) {
        case `appIndex`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 3,
            yAxisID: `AppIndex`,
          });
          break;
        }
        case `appPleurisyPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.APP_PLEURISY_PREVALENCE.BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.APP_PLEURISY_PREVALENCE.BORDER,
            borderWidth: 1,
            barPercentage: 0.2,
            type: `line`,
            order: 2,
            yAxisID: `Prevalence`,
            pointStyle: `rectRot`,
            fill: false,
          });
          break;
        }
        case `pleurisyPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.PLEURISY_PREVALENCE.BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.APP_INDEX_AND_PREVALENCE.PLEURISY_PREVALENCE.BORDER,
            pointBorderWidth: 3,
            borderWidth: 1,
            type: `line`,
            order: 1,
            yAxisID: `Prevalence`,
            pointStyle: `cross`,
            fill: false,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  generatePneumoniaIndexAndPrevalenceDataset(analysisDetails: SummaryAnalysis[]) {
    const labels = [];
    const datasets: Chart.ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForBronchopneumoniaScore" &&
          key !== "numberOfLungsExaminedForPleuritis" &&
          !datasetDict[key]
        ) {
          datasetDict[key] = [];
          colorDict[key] = [];
          borderDict[key] = [];
        }
        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `pneumoniaIndex`: {
            //datasetDict[key].push(`${value},${details[`identifier`]}`);
            datasetDict[key].push(value);
            if (value < 0.56) {
              colorDict[key].push(`#D6FFA4`);
              borderDict[key].push(`#6c9d31`);
            }
            if (value >= 0.56 && value <= 0.89) {
              colorDict[key].push(`#FFF084`);
              borderDict[key].push(`#745E05`);
            }
            if (value >= 0.9) {
              colorDict[key].push(`#FFEAEA`);
              borderDict[key].push(`#FF4C4B`);
            }
            break;
          }
          case `pneumoniaPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.PNEUMONIA_PREVALENCE
                .BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.PNEUMONIA_PREVALENCE.BORDER
            );
            break;
          }
          case `bronchopneumoniaPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.BRONCHOPNEUMONIA_PREVALENCE
                .BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.BRONCHOPNEUMONIA_PREVALENCE
                .BORDER
            );
            break;
          }
          case `averageAffectedSurfaceOutOfLungs`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS.BORDER
            );
            break;
          }
          case `averageAffectedSurfaceOutOfLungsWithActivePneumonia`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS_WITH_ACTIVE_PNEUMONIA.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS_WITH_ACTIVE_PNEUMONIA.BORDER
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    // After parsing the values, we can parse it to fit ChartJS
    for (const key in datasetDict) {
      switch (key) {
        case `pneumoniaIndex`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            barPercentage: 0,
            categoryPercentage: 10,
            type: `bar`,
            order: 5,
            yAxisID: `PneumoniaIndex`,
          });
          break;
        }
        case `pneumoniaPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.PNEUMONIA_PREVALENCE
                .BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.PNEUMONIA_PREVALENCE.BORDER,
            borderWidth: 1,
            barPercentage: 0.2,
            type: `line`,
            order: 1,
            yAxisID: `Prevalence`,
            pointStyle: `triangle`,
            fill: false,
          });
          break;
        }
        case `bronchopneumoniaPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.BRONCHOPNEUMONIA_PREVALENCE
                .BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE.BRONCHOPNEUMONIA_PREVALENCE
                .BORDER,
            borderWidth: 1,
            barPercentage: 0.2,
            type: `line`,
            order: 2,
            yAxisID: `Prevalence`,
            pointStyle: `rectRot`,
            fill: false,
          });
          break;
        }
        case `averageAffectedSurfaceOutOfLungs`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS.BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS.BORDER,
            borderWidth: 1,
            barPercentage: 0.2,
            type: `line`,
            order: 3,
            yAxisID: `Prevalence`,
            pointStyle: `rect`,
            fill: false,
          });
          break;
        }
        case `averageAffectedSurfaceOutOfLungsWithActivePneumonia`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS_WITH_ACTIVE_PNEUMONIA.BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.PNEUMONIA_INDEX_AND_PREVALENCE
                .AVERAGE_AFFECTED_SURFACE_OUT_OF_LUNGS_WITH_ACTIVE_PNEUMONIA.BORDER,
            pointBorderWidth: 3,
            borderWidth: 1,
            type: `line`,
            order: 4,
            yAxisID: `Prevalence`,
            pointStyle: `cross`,
            fill: false,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  generatePleurisyDataset(analysisDetails: SummaryAnalysis[]) {
    const labels = [];
    const datasets: Chart.ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForPleuritis" &&
          !datasetDict[key]
        ) {
          if (key === "pleurisyPercentageDict") {
            for (const pleurisyKey in value) {
              if (!datasetDict[pleurisyKey]) {
                datasetDict[`${pleurisyKey}`] = [];
                colorDict[`${pleurisyKey}`] = [];
                borderDict[`${pleurisyKey}`] = [];
              }
            }
          } else {
            datasetDict[key] = [];
            colorDict[key] = [];
            borderDict[key] = [];
          }
        }
        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `pleurisyPercentageDict`: {
            for (const pleurisyKey in value) {
              datasetDict[`${pleurisyKey}`].push(value[pleurisyKey]);
              if (pleurisyKey === `P0`) {
                colorDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P0.BACKGROUND);
                borderDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P0.BORDER);
              }
              if (pleurisyKey === `P1`) {
                colorDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P1.BACKGROUND);
                borderDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P1.BORDER);
              }
              if (pleurisyKey === `P2`) {
                colorDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P2.BACKGROUND);
                borderDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P2.BORDER);
              }
              if (pleurisyKey === `P3`) {
                colorDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P3.BACKGROUND);
                borderDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P3.BORDER);
              }
              if (pleurisyKey === `P4`) {
                colorDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P4.BACKGROUND);
                borderDict[`${pleurisyKey}`].push(ANALYSIS_COLORS.PLEURISY.P4.BORDER);
              }
            }
            break;
          }
          case `pleurisyPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(ANALYSIS_COLORS.PLEURISY.PLEURISY_PREVALENCE.BACKGROUND);
            borderDict[key].push(ANALYSIS_COLORS.PLEURISY.PLEURISY_PREVALENCE.BORDER);
            break;
          }
          default: {
            break;
          }
        }
      }
    }

    // After parsing the values, we can parse it to fit ChartJS
    for (const key in datasetDict) {
      switch (key) {
        case `P0`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 2,
          });
          break;
        }
        case `P1`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 3,
          });
          break;
        }
        case `P2`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 4,
          });
          break;
        }
        case `P3`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 5,
          });
          break;
        }
        case `P4`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 6,
          });
          break;
        }
        case `pleurisyPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor: ANALYSIS_COLORS.PLEURISY.PLEURISY_PREVALENCE.BACKGROUND,
            borderColor: ANALYSIS_COLORS.PLEURISY.PLEURISY_PREVALENCE.BORDER,
            pointBorderWidth: 3,
            borderWidth: 1,
            type: `line`,
            order: 1,
            pointStyle: `cross`,
            fill: false,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  generateAbscessScarringPericarditisDataset(
    analysisDetails: SummaryAnalysis[],
    showBarChart: boolean
  ) {
    const labels = [];
    const datasets: ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForAbscess" &&
          key !== "numberOfLungsExaminedForScarring" &&
          key !== "numberOfLungsExaminedForPericarditis" &&
          !datasetDict[key]
        ) {
          datasetDict[key] = [];
          colorDict[key] = [];
          borderDict[key] = [];
        }

        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `abscess`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.ABSCESS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.ABSCESS.BORDER
            );
            break;
          }
          case `scarring`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.SCARRING.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.SCARRING.BORDER
            );
            break;
          }
          case `pericarditis`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.PERICARDITIS.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.PERICARDITIS.BORDER
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    if (showBarChart === true) {
      // After parsing the values, we can parse it to fit ChartJS
      for (const key in datasetDict) {
        datasets.push({
          label: `${VARIABLES_TO_WORDS[key]}`,
          data: datasetDict[key],
          backgroundColor: colorDict[key],
          borderColor: borderDict[key],
          borderWidth: 0.8,
          barThickness: 40,
        });
      }
    } else if (showBarChart === false) {
      for (const key in datasetDict) {
        switch (key) {
          case `abscess`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              barThickness: 40,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.ABSCESS.BACKGROUND,
              borderColor: ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.ABSCESS.BORDER,
            });
            break;
          }
          case `scarring`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              barThickness: 40,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.SCARRING.BACKGROUND,
              borderColor: ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.SCARRING.BORDER,
            });
            break;
          }
          case `pericarditis`: {
            datasets.push({
              label: `${VARIABLES_TO_WORDS[key]}`,
              data: datasetDict[key],
              pointBackgroundColor: colorDict[key],
              pointBorderColor: borderDict[key],
              borderWidth: 0.8,
              barThickness: 40,
              type: `line`,
              fill: false,
              backgroundColor:
                ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.PERICARDITIS.BACKGROUND,
              borderColor:
                ANALYSIS_COLORS.ABSCESS_SCARRING_PERICARDITIS.PERICARDITIS.BORDER,
            });
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  generateBronchopneumoniaScoreDataset(analysisDetails: SummaryAnalysis[]) {
    const labels = [];
    const datasets: Chart.ChartDataSets[] = [];
    const datasetDict: { [key: string]: number[] } = {};
    const colorDict: { [key: string]: string[] } = {};
    const borderDict: { [key: string]: string[] } = {};
    // Parse Dataset and Labels
    for (const details of analysisDetails) {
      for (const [key, value] of Object.entries(details)) {
        if (
          key !== "identifier" &&
          key !== "numberOfLungsExaminedForBronchopneumoniaScore" &&
          !datasetDict[key]
        ) {
          if (key === "pneumoniaPercentageDict") {
            for (const pneumoniaKey in value) {
              if (!datasetDict[`pneumonia${pneumoniaKey}`]) {
                datasetDict[`pneumonia${pneumoniaKey}`] = [];
                colorDict[`pneumonia${pneumoniaKey}`] = [];
                borderDict[`pneumonia${pneumoniaKey}`] = [];
              }
            }
          } else {
            datasetDict[key] = [];
            colorDict[key] = [];
            borderDict[key] = [];
          }
        }
        switch (key) {
          case `identifier`: {
            let parsedValue = value;
            if (parsedValue.includes(",")) {
              parsedValue = `${
                value.split(",")[0]
              } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                new Date(value.split(",")[1])
              )})`;
            }
            labels.push(parsedValue);
            break;
          }
          case `pneumoniaPercentageDict`: {
            for (const bronchopneumoniaKey in value) {
              datasetDict[`pneumonia${bronchopneumoniaKey}`].push(
                value[bronchopneumoniaKey]
              );
              if (bronchopneumoniaKey === `0`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_0.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_0.BORDER
                );
              }
              if (bronchopneumoniaKey === `1`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_1.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_1.BORDER
                );
              }
              if (bronchopneumoniaKey === `2`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_2.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_2.BORDER
                );
              }
              if (bronchopneumoniaKey === `3`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_3.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_3.BORDER
                );
              }
              if (bronchopneumoniaKey === `4`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_4.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_4.BORDER
                );
              }
              if (bronchopneumoniaKey === `5`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_5.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_5.BORDER
                );
              }
              if (bronchopneumoniaKey === `6`) {
                colorDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_6.BACKGROUND
                );
                borderDict[`pneumonia${bronchopneumoniaKey}`].push(
                  ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_6.BORDER
                );
              }
            }
            break;
          }
          case `pneumoniaPrevalence`: {
            datasetDict[key].push(value);
            colorDict[key].push(
              ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_PREVALENCE.BACKGROUND
            );
            borderDict[key].push(
              ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_PREVALENCE.BORDER
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }

    // After parsing the values, we can parse it to fit ChartJS
    for (const key in datasetDict) {
      switch (key) {
        case `pneumonia0`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 2,
          });
          break;
        }
        case `pneumonia1`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 3,
          });
          break;
        }
        case `pneumonia2`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 4,
          });
          break;
        }
        case `pneumonia3`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 5,
          });
          break;
        }
        case `pneumonia4`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 6,
          });
          break;
        }
        case `pneumonia5`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 7,
          });
          break;
        }
        case `pneumonia6`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            backgroundColor: colorDict[key],
            borderColor: borderDict[key],
            borderWidth: 0.8,
            barThickness: 40,
            type: `bar`,
            order: 8,
          });
          break;
        }
        case `pneumoniaPrevalence`: {
          datasets.push({
            label: `${VARIABLES_TO_WORDS[key]}`,
            data: datasetDict[key],
            pointBackgroundColor: colorDict[key],
            pointBorderColor: borderDict[key],
            backgroundColor:
              ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_PREVALENCE.BACKGROUND,
            borderColor:
              ANALYSIS_COLORS.BRONCHOPNEUMONIA_SCORE.PNEUMONIA_PREVALENCE.BORDER,
            borderWidth: 1,
            barPercentage: 0.2,
            type: `line`,
            order: 1,
            pointStyle: `triangle`,
            fill: false,
          });
          break;
        }
        default: {
          break;
        }
      }
    }
    return { labels: labels, datasets: datasets };
  }

  @Watch(`store.analysisData`)
  showGraph() {
    if (
      this.store.analysisData &&
      this.analysisSectionSelectedOption &&
      this.analysisSectionComparisonOption
    ) {
      this.chartData = null;
      this.options = null;
      this.analysisResponse = this.store.analysisData;
      this.showPlaceholder = false;
      const analysisDetails: SummaryAnalysis[] = [];
      // First, we can combine all values into 1 object
      if (this.analysisResponse.farmUnits) {
        analysisDetails.push(...this.analysisResponse.farmUnits);
      }
      if (this.analysisResponse.industryAverages) {
        analysisDetails.push(...this.analysisResponse.industryAverages);
      }
      if (this.analysisResponse.individualReports) {
        analysisDetails.push(...this.analysisResponse.individualReports);
      }

      // If the graph is Lung Lesion Score Summary
      if (
        this.analysisSectionSelectedOption.value ===
          AnalysisDropdownSections.LUNG_LESION_SCORE_SUMMARY &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generateLungLesionScoreSummaryDataset(
          analysisDetails,
          this.showBarChart
        );
        this.chartData = { labels: [...labels], datasets: [...datasets] };
        this.options = LUNG_LESION_SCORE_SUMMARY_ANALYSIS_GRAPH_CONFIG;
      }

      // If the graph is App Index and Prevalence
      if (
        this.analysisSectionSelectedOption.value ===
          AnalysisDropdownSections.APP_INDEX_AND_PREVALENCE &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generateAppIndexAndPrevalenceDataset(
          analysisDetails
        );
        this.options = APP_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG;
        this.options.showLines =
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH
            ? true
            : false;
        this.chartData = {
          labels: [...labels],
          datasets: [...datasets],
        };
      }

      // If the graph is Pneumonia Index and Prevalence
      if (
        this.analysisSectionSelectedOption.value ===
          AnalysisDropdownSections.PNEUMONIA_INDEX_AND_PREVALENCE &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generatePneumoniaIndexAndPrevalenceDataset(
          analysisDetails
        );
        this.options = PNEUMONIA_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG;
        this.options.showLines =
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH
            ? true
            : false;
        this.chartData = {
          labels: [...labels],
          datasets: [...datasets],
        };
      }

      // When the option is Pleurisy
      if (
        this.analysisSectionSelectedOption.value === AnalysisDropdownSections.PLEURISY &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generatePleurisyDataset(analysisDetails);
        this.options = PLEURISY_GRAPH_ANALYSIS_CONFIG;
        this.options.showLines =
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH
            ? true
            : false;
        this.chartData = {
          labels: [...labels],
          datasets: [...datasets],
        };
      }

      // When the graph is Abscess Scarring and Pericarditis
      if (
        this.analysisSectionSelectedOption.value ===
          AnalysisDropdownSections.ABSCESS_SCARRING_PERICARDITIS &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generateAbscessScarringPericarditisDataset(
          analysisDetails,
          this.showBarChart
        );
        this.chartData = { labels: [...labels], datasets: [...datasets] };
        this.options = ABSCESS_SCARRING_PERICARDITIS_GRAPH_ANALYSIS_CONFIG;
      }

      // If the graph is Bronchopneumonia Score
      if (
        this.analysisSectionSelectedOption.value ===
          AnalysisDropdownSections.BRONCHOPNEUMONIA_SCORE &&
        (this.analysisSectionComparisonOption.value ===
          this.analysisCriteria.MISCELLANEOUS ||
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH)
      ) {
        const { labels, datasets } = this.generateBronchopneumoniaScoreDataset(
          analysisDetails
        );
        this.options = BRONCHOPNEUMONIA_SCORE_ANALYSIS_GRAPH_CONFIG;
        this.options.showLines =
          this.analysisSectionComparisonOption.value === this.analysisCriteria.MONTH
            ? true
            : false;
        this.chartData = {
          labels: [...labels],
          datasets: [...datasets],
        };
      }
    }
  }

  get miscellaneousComparisonTitle() {
    if (
      this.analysisResponse &&
      this.analysisSectionComparisonOption &&
      this.analysisSectionSelectedOption
    ) {
      const queries: string[] = [];
      const details: SummaryAnalysis[] = [];
      if (this.analysisResponse.farmUnits) {
        details.push(...this.analysisResponse.farmUnits);
      }
      if (this.analysisResponse.industryAverages) {
        details.push(...this.analysisResponse.industryAverages);
      }
      if (this.analysisResponse.individualReports) {
        this.analysisResponse.individualReports.forEach((data) => {
          if (data.identifier) {
            data.identifier = `${
              data.identifier.split(",")[0]
            } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
              new Date(data.identifier.split(",")[1])
            )})`;
          }
        });
        details.push(...this.analysisResponse.individualReports);
      }
      if (details.length > 1) {
        for (let index = 0; index < details.length - 1; index++) {
          if (details[index]) {
            queries.push(details[index].identifier as string);
          }
        }
        if (details[details.length - 1]) {
          queries.push(details[details.length - 1].identifier as string);
        }
      } else {
        if (details[0]) {
          queries.push(details[0].identifier as string);
        }
      }
      // Compile details into a custom object
      const output: MiscellaneousComparisonTitleDetails = {
        selectedOption: this.analysisSectionSelectedOption.text,
        selectedComparison: this.analysisSectionComparisonOption.text,
        startDate: moment(this.showStartDate).local().format(`MMM YYYY`),
        endDate: moment(this.showEndDate).local().format(`MMM YYYY`),
        queries: queries,
      };
      return output;
    }
    return ``;
  }

  get monthComparisonTitle() {
    if (
      this.analysisResponse &&
      this.analysisResponse.farmUnitCodeName &&
      this.analysisSectionComparisonOption &&
      this.analysisSectionSelectedOption
    ) {
      const output: MonthComparisonTitleDetails = {
        selectedOption: this.analysisSectionSelectedOption.text,
        selectedComparison: this.analysisSectionComparisonOption.text,
        startDate: moment(this.showStartDate).local().format(`MMM YYYY`),
        endDate: moment(this.showEndDate).local().format(`MMM YYYY`),
        farmUnitCodeName: this.analysisResponse.farmUnitCodeName,
      };
      return output;
    }
    return ``;
  }
}
