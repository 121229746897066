
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({})
export default class LineChart extends Mixins(Line, Vue, mixins.reactiveProp) {
  @Prop({
    type: Object,
    default: null,
  })
  options!: Chart.ChartOptions | null;

  mounted() {
    this.addPlugin(ChartDataLabels);
    if (this.chartData && this.options) {
      this.renderChart(this.chartData, this.options);
    }
  }
}
