import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { DropdownOption } from '@/models/swine/dropdown.model';
import {
  AnalysisDropdownSelectionQueries,
  AnalysisDropdownUpdate,
  AnalysisParams,
  AnalysisQueries,
  SummaryAnalysisOutput,
} from '@/models/swine/analysis.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { Country } from '@/models/swine/country/country.model';
import { Region } from '@/models/swine/regions/region.model';
import { State } from '@/models/swine/states/state.model';
import { Client } from '@/models/swine/client/client.model';
import { FetchClientsRequestParams } from '../client/client.list';
import { Farm } from '@/models/swine/farm/farm.model';
import { FarmUnit } from '@/models/swine/farmUnit/farmUnit.model';
import { generateUniqueId } from '@/utils/lodash';
import { IndividualReportDropdownResponse } from '@/models/swine/report/report.model';
import { convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp } from '@/utils/moment';
import { speciesType } from '@/constants';

@Module({
  namespaced: true,
  dynamic: true,
  name: `analysis.detail`,
  store,
})
class AnalysisDetails extends VuexModule {
  isLoading = false;
  error: any | null = null;
  countries: DropdownOption[] = [];
  industryAverageCountries: DropdownOption[] = [];
  regions: AnalysisDropdownUpdate[] = [];
  states: AnalysisDropdownUpdate[] = [];
  clients: AnalysisDropdownUpdate[] = [];
  farms: AnalysisDropdownUpdate[] = [];
  farmUnits: AnalysisDropdownUpdate[] = [];
  auditReports: AnalysisDropdownUpdate[] = [];
  countryId: string | null = null;
  regionId: string | null = null;
  stateId: string | null = null;
  clientId: string | null = null;
  farmId: string | null = null;
  farmUnitId: string | null = null;
  analysisData: SummaryAnalysisOutput | null = null;
  totalReportsCount: number | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  setCountryOptions(payload: DropdownOption[]) {
    this.countries = payload;
  }

  @Mutation
  setIndustryAveragesCountryOptions(payload: DropdownOption[]) {
    this.industryAverageCountries = payload;
  }

  @Mutation
  setRegionOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.regions.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      const newRegions: AnalysisDropdownUpdate[] = [];
      this.regions.forEach((data, currentIndex) => {
        if (currentIndex !== index) {
          newRegions.push(data);
        } else {
          newRegions.push(payload);
        }
      });
      this.regions = newRegions;
    } else {
      this.regions.push(payload);
    }
  }

  @Mutation
  replaceCountryOptions(payload: DropdownOption[]) {
    this.countries = payload;
  }

  @Mutation
  replaceIndustryAveragesCountryOptions(payload: DropdownOption[]) {
    this.industryAverageCountries = payload;
  }

  @Mutation
  replaceRegionOptions(payload: AnalysisDropdownUpdate[]) {
    this.regions = payload;
  }

  @Mutation
  setTotalNumberOfAuditReports(payload: number) {
    this.totalReportsCount = payload;
  }

  @Mutation
  setStateOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.states.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      const newStates: AnalysisDropdownUpdate[] = [];
      this.states.forEach((data, currentIndex) => {
        if (currentIndex !== index) {
          newStates.push(data);
        } else {
          newStates.push(payload);
        }
      });
      this.states = newStates;
    } else {
      this.states.push(payload);
    }
  }

  @Mutation
  replaceStateOptions(payload: AnalysisDropdownUpdate[]) {
    this.states = payload;
  }

  @Mutation
  setClientOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.clients.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      const newClients: AnalysisDropdownUpdate[] = [];
      this.clients.forEach((data, currentIndex) => {
        if (currentIndex !== index) {
          newClients.push(data);
        } else {
          newClients.push(payload);
        }
      });
      this.clients = newClients;
    } else {
      this.clients.push(payload);
    }
  }

  @Mutation
  replaceClientOptions(payload: AnalysisDropdownUpdate[]) {
    this.clients = payload;
  }

  @Mutation
  setFarmOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.farms.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      const newFarms: AnalysisDropdownUpdate[] = [];
      this.farms.forEach((data, currentIndex) => {
        if (currentIndex !== index) {
          newFarms.push(data);
        } else {
          newFarms.push(payload);
        }
      });
      this.farms = newFarms;
    } else {
      this.farms.push(payload);
    }
  }

  @Mutation
  replaceFarmOptions(payload: AnalysisDropdownUpdate[]) {
    this.farms = payload;
  }

  @Mutation
  setFarmUnitOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.farmUnits.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      const newFarmUnits: AnalysisDropdownUpdate[] = [];
      this.farmUnits.forEach((data, currentIndex) => {
        if (currentIndex !== index) {
          newFarmUnits.push(data);
        } else {
          newFarmUnits.push(payload);
        }
      });
      this.farmUnits = newFarmUnits;
    } else {
      this.farmUnits.push(payload);
    }
  }

  @Mutation
  replaceFarmUnitOptions(payload: AnalysisDropdownUpdate[]) {
    this.farmUnits = payload;
  }

  @Mutation
  setAuditReportOptions(payload: AnalysisDropdownUpdate) {
    const { id } = payload;
    const index = this.auditReports.findIndex((details) => {
      return details.id === id;
    });
    if (index > -1) {
      this.auditReports = this.auditReports.filter((details) => {
        return details.id !== id;
      });
    }
    this.auditReports.push(payload);
  }

  @Mutation
  replaceAuditReportsOptions(payload: AnalysisDropdownUpdate[]) {
    this.auditReports = payload;
  }

  @Mutation
  setCountryId(countryId: string) {
    this.countryId = countryId;
  }

  @Mutation
  setRegionId(regionId: string) {
    this.regionId = regionId;
  }

  @Mutation
  setStateId(stateId: string) {
    this.stateId = stateId;
  }

  @Mutation
  setClientId(clientId: string) {
    this.clientId = clientId;
  }

  @Mutation
  setFarmId(farmId: string) {
    this.farmId = farmId;
  }

  @Mutation
  setFarmUnitId(farmUnitId: string) {
    this.farmUnitId = farmUnitId;
  }

  @Mutation
  setAnalysisOutput(analysisData: SummaryAnalysisOutput) {
    this.analysisData = analysisData;
  }

  @Action
  getAllCountries(params: AnalysisParams) {
    const { queries } = params;
    const { startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<Country[]>(`${Endpoints.Countries}${Endpoints.Dropdown}`, {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        })
        .then((response) => {
          // Parse the data to what we need
          const countryDetails: DropdownOption[] = [];
          // Push a default All value
          response.data.map((details) => {
            countryDetails.push({
              text: details.code,
              value: String(details.id),
              uniqueKey: generateUniqueId(`${details.name}_`),
            });
          });
          this.context.commit('setCountryOptions', countryDetails);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllIndustryAverageCountries(params: AnalysisParams) {
    const { queries } = params;
    const { startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<Country[]>(`${Endpoints.Countries}${Endpoints.Dropdown}${Endpoints.IndustryAverages}`, {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        })
        .then((response) => {
          // Parse the data to what we need
          const countryDetails: DropdownOption[] = [];
          // Push a default All value
          response.data.map((details) => {
            countryDetails.push({
              text: details.code,
              value: String(details.id),
              uniqueKey: generateUniqueId(`${details.name}_`),
            });
          });
          this.context.commit('setIndustryAveragesCountryOptions', countryDetails);
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllRegionByCountryId(params: AnalysisParams) {
    const { queries, isAllAvailable } = params;
    const { specifiedId, countryId, startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      if (countryId) {
        this.context.commit(`setCountryId`, countryId);
        axios
          .get<Region[]>(`${Endpoints.Regions}${Endpoints.Dropdown}`, {
            params: {
              country: this.countryId,
              startDate: startDate,
              endDate: endDate,
            },
          })
          .then((response) => {
            // Parse the data to what we need
            const regionDetails: DropdownOption[] = [];
            if (isAllAvailable) {
              regionDetails.push({
                text: `All`,
                value: `all`,
                uniqueKey: generateUniqueId(`all_`),
              });
            }
            response.data.map((details) => {
              regionDetails.push({
                text: details.code,
                value: String(details.id),
                uniqueKey: generateUniqueId(`${details.name}_`),
              });
            });
            if (specifiedId !== null) {
              const parsedDetails: AnalysisDropdownUpdate = {
                id: specifiedId,
                data: regionDetails,
              };
              this.context.commit('setRegionOptions', parsedDetails);
            }
          });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllStatesByRegionId(params: AnalysisParams) {
    const { queries, isAllAvailable } = params;
    const { specifiedId, regionId, startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      if (regionId) {
        this.context.commit(`setRegionId`, regionId);
        axios
          .get<State[]>(`${Endpoints.States}${Endpoints.Dropdown}`, {
            params: {
              region: this.regionId,
              startDate: startDate,
              endDate: endDate,
            },
          })
          .then((response) => {
            // Parse the data to what we need
            const stateDetails: DropdownOption[] = [];
            if (isAllAvailable) {
              stateDetails.push({
                text: `All`,
                value: `all`,
                uniqueKey: generateUniqueId(`all_`),
              });
            }
            response.data.map((details) => {
              stateDetails.push({
                text: details.code,
                value: String(details.id),
                uniqueKey: generateUniqueId(`${details.name}_`),
              });
            });
            if (specifiedId !== null) {
              const parsedDetails: AnalysisDropdownUpdate = {
                id: specifiedId,
                data: stateDetails,
              };
              this.context.commit('setStateOptions', parsedDetails);
            }
          });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async getAllClientsByCountryRegionStateIds(params: AnalysisParams) {
    const { queries, isAllAvailable } = params;
    const {
      specifiedId,
      countryId,
      regionId,
      stateId,
      startDate,
      endDate,
    } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      if (regionId && startDate && endDate && specifiedId !== null) {
        const params: FetchClientsRequestParams = {
          country: countryId as string,
          state: stateId as string,
          region: regionId,
          startDate: startDate,
          endDate: endDate,
          species: speciesType.Swine
        };
        const response = await axios.get<Client[]>(
          `${Endpoints.Clients}${Endpoints.Dropdown}`,
          {
            params,
          },
        );
        if (response.data) {
          const clientDetails: DropdownOption[] = [];
          if (isAllAvailable) {
            clientDetails.push({
              text: `All`,
              value: `all`,
              uniqueKey: generateUniqueId(`all_`),
            });
          }
          response.data.map((details) => {
            clientDetails.push({
              text: details.clientCode,
              value: String(details.id),
              uniqueKey: generateUniqueId(`${details.name}_`),
            });
          });
          const parsedDetails: AnalysisDropdownUpdate = {
            id: specifiedId,
            data: clientDetails,
          };
          this.context.commit('setClientOptions', parsedDetails);
        }
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllFarmsByClientId(params: AnalysisParams) {
    const { queries, isAllAvailable } = params;
    const { specifiedId, clientId, startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.context.commit(`setClientId`, clientId);
      if (this.clientId) {
        axios
          .get<Farm[]>(`${Endpoints.Farms}${Endpoints.Dropdown}`, {
            params: {
              client: this.clientId,
              startDate: startDate,
              endDate: endDate,
            },
          })
          .then((response) => {
            const farmDetails: DropdownOption[] = [];
            if (isAllAvailable) {
              farmDetails.push({
                text: `All`,
                value: `all`,
                uniqueKey: generateUniqueId(`all_`),
              });
            }
            response.data.map((details) => {
              farmDetails.push({
                text: details.farmCode,
                value: String(details.id),
                uniqueKey: generateUniqueId(`${details.name}_`),
              });
            });
            if (specifiedId !== null) {
              const parsedDetails: AnalysisDropdownUpdate = {
                id: specifiedId,
                data: farmDetails,
              };
              this.context.commit(`setFarmOptions`, parsedDetails);
            }
          });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllFarmUnitsByFarmId(params: AnalysisParams) {
    const { queries, isAllAvailable } = params;
    const { specifiedId, farmId, startDate, endDate } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.context.commit(`setFarmId`, farmId);
      if (this.farmId) {
        axios
          .get<FarmUnit[]>(`${Endpoints.FarmUnits}${Endpoints.Dropdown}`, {
            params: {
              farm: this.farmId,
              startDate: startDate,
              endDate: endDate,
            },
          })
          .then((response) => {
            const farmUnitDetails: DropdownOption[] = [];
            if (isAllAvailable) {
              farmUnitDetails.push({
                text: `All`,
                value: `all`,
                uniqueKey: generateUniqueId(`all_`),
              });
            }
            response.data.map((details) => {
              farmUnitDetails.push({
                text: details.farmUnitCode,
                value: String(details.id),
                uniqueKey: generateUniqueId(`${details.farmUnitCode}_`),
              });
            });
            if (specifiedId !== null) {
              const parsedDetails: AnalysisDropdownUpdate = {
                id: specifiedId,
                data: farmUnitDetails,
              };
              this.context.commit(`setFarmUnitOptions`, parsedDetails);
            }
          });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAllAuditReports(queries: AnalysisDropdownSelectionQueries) {
    const {
      specifiedId,
      searchQuery,
      limit,
      offset,
      startDate,
      endDate,
    } = queries;
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<IndividualReportDropdownResponse>(
          `${Endpoints.Reports}${Endpoints.Swine}${Endpoints.Dropdown}`,
          {
            params: {
              query: searchQuery,
              limit: limit,
              offset: offset,
              startDate: startDate,
              endDate: endDate,
            },
          },
        )
        .then((response) => {
          const reportDetails: DropdownOption[] = [];
          if (response.data) {
            response.data.reports.map((details) => {
              reportDetails.push({
                text: `${
                  details.farmUnitCodeName
                } (${convertDateToLocalDDMMYYYYWithSlashesWithoutTimestamp(
                  details.publishedDate,
                )})`,
                value: String(details.id),
                uniqueKey: generateUniqueId(`${details.farmUnitCodeName}_`),
              });
            });
            if (specifiedId !== null) {
              const parsedDetails: AnalysisDropdownUpdate = {
                id: specifiedId,
                data: reportDetails,
                totalCount: response.data.totalCount,
              };
              this.context.commit(`setAuditReportOptions`, parsedDetails);
            }
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  getAnalysis(params: AnalysisQueries) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get<SummaryAnalysisOutput>(`${Endpoints.Analysis}`, {
          params: {
            ...params,
          },
        })
        .then((response) => {
          if (response.data) {
            this.context.commit(`setAnalysisOutput`, response.data);
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(AnalysisDetails);
