
















































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import SplitDropdown from "@/components/swine/dropdowns/SplitDropdown.vue";
import DateDropdown from "@/components/swine/dropdowns/DateDropdown.vue";
import { ADD_COMPARISONS_DATA } from "@/utils/constants";
import { DropdownOption } from "@/models/swine/dropdown.model";
import FarmUnitAnalysisMultiDropdown from "../dropdowns/FarmUnitAnalysisMultiDropdown.vue";
import IndustryAverageDropdown from "../dropdowns/IndustryAverageDropdown.vue";
import IndividualReportDropdown from "../dropdowns/IndividualReportDropdown.vue";
import { AnalysisStores } from "@/store/swine/analysis";
import { getLastDayOfMonth } from "@/utils/moment";
@Component({
  components: {
    SplitDropdown,
    DateDropdown,
    FarmUnitAnalysisMultiDropdown,
    IndustryAverageDropdown,
    IndividualReportDropdown,
  },
})
export default class MiscellaneousAnalysis extends Vue {
  addComparisonOptions: DropdownOption[] = [];
  startDate: Date | null = null;
  endDate: Date | null = null;
  currentLocationSelectionLimit = 0;
  queriesArray: { [key: string]: string | null }[] = [];
  store = AnalysisStores.detail;
  resetStartDateFilter = false;
  resetEndDateFilter = false;
  resetQueries: { [key: number]: boolean } | null = null;
  // Reason why we can do this is because we know the logical order of things
  keys = [
    `query_id`,
    `country`,
    `region`,
    `state`,
    `client`,
    `farm`,
    `farm_unit`,
    `report_id`,
    `comparison_type`,
  ];

  @Prop({
    type: Boolean,
    default: false,
  })
  resetQueryFilters!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDateFilters!: boolean;

  @Watch(`resetQueryFilters`)
  resetAllFilters() {
    if (this.resetQueryFilters) {
      this.resetQueries = {};
      for (let index = 0; index < this.currentLocationSelectionLimit; index++) {
        this.resetQueries[index] = true;
      }
    }
  }

  @Watch(`resetDateFilters`)
  resetDateSelections() {
    if (this.resetDateFilters) {
      this.resetStartDateFilter = true;
      this.resetEndDateFilter = true;
    }
  }

  resetStartDateComplete(value: boolean) {
    if (this.resetStartDateFilter) {
      this.resetStartDateFilter = value;
      this.resetDateProcessComplete();
    }
  }

  resetEndDateComplete(value: boolean) {
    if (this.resetEndDateFilter) {
      this.resetEndDateFilter = value;
      this.resetDateProcessComplete();
    }
  }

  @Emit("resetDates")
  resetDateProcessComplete() {
    if (!this.resetStartDateFilter && !this.resetEndDateFilter) {
      this.startDate = null;
      this.endDate = null;
      return false;
    }
  }

  resetQueryUpdate(value: number) {
    if (this.resetQueries && this.resetQueries[value] !== null) {
      this.resetQueries[value] = false;
    }
    this.resetProcessComplete();
  }

  @Emit("reset")
  resetProcessComplete() {
    let resetQueriesFilter = false;
    for (const key in this.resetQueries) {
      if (this.resetQueries[parseInt(key)] === true) {
        resetQueriesFilter = true;
      }
    }
    if (!resetQueriesFilter) {
      this.resetQueries = null;
      this.store.replaceCountryOptions([]);
      this.store.replaceIndustryAveragesCountryOptions([]);
      this.store.replaceRegionOptions([]);
      this.store.replaceStateOptions([]);
      this.store.replaceClientOptions([]);
      this.store.replaceFarmOptions([]);
      this.store.replaceFarmUnitOptions([]);
      this.store.replaceAuditReportsOptions([]);
      this.returnQueries();
      return false;
    }
  }

  mounted() {
    this.addComparisonOptions = ADD_COMPARISONS_DATA;
  }

  createBaseQueriesDict() {
    const queries: { [key: string]: string | null } = {};
    this.keys.map((key) => {
      queries[key] = null;
    });
    return queries;
  }

  returnComparisonOption(option: DropdownOption) {
    if (this.currentLocationSelectionLimit < 5) {
      this.currentLocationSelectionLimit++;
      const newDict: {
        [key: string]: string | null;
      } = this.createBaseQueriesDict();
      newDict[`comparison_type`] = option.value;
      newDict[`query_id`] = String(this.currentLocationSelectionLimit - 1);
      this.queriesArray.push(newDict);
      this.returnQueries();
    }
  }

  @Emit("selectStartDate")
  setStartDate(date: Date) {
    this.startDate = date;
    return this.startDate;
  }

  @Emit("selectEndDate")
  setEndDate(date: Date | null) {
    this.endDate = date ? getLastDayOfMonth(date) : date;
    return this.endDate;
  }

  removeDropdown(specifiedId: number) {
    // First filter all the dropdown values and readjust the ids if the id exceeds the selected id value
    const filteredRegions = this.store.regions.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    const filteredStates = this.store.states.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    const filteredClients = this.store.clients.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    const filteredFarms = this.store.farms.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    const filteredFarmUnits = this.store.farmUnits.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    const auditReports = this.store.auditReports.filter((details) => {
      if (details.id !== specifiedId) {
        if (specifiedId < details.id) {
          details.id = details.id - 1;
        }
        return details;
      }
    });
    this.store.replaceRegionOptions(filteredRegions);
    this.store.replaceStateOptions(filteredStates);
    this.store.replaceClientOptions(filteredClients);
    this.store.replaceFarmOptions(filteredFarms);
    this.store.replaceFarmUnitOptions(filteredFarmUnits);
    this.store.replaceAuditReportsOptions(auditReports);

    // Filter the Queries Array
    this.queriesArray = this.queriesArray.filter((details) => {
      if (details[`query_id`] && specifiedId !== parseInt(details[`query_id`])) {
        if (specifiedId < parseInt(details[`query_id`])) {
          details[`query_id`] = String(parseInt(details[`query_id`]) - 1);
        }
        return details;
      }
    });
    this.currentLocationSelectionLimit--;
    this.returnQueries();
  }

  updateQueryDetails(queryDetails: { [key: string]: string | null }) {
    const index = this.queriesArray.findIndex((details) => {
      return details[`query_id`] === queryDetails[`query_id`];
    });

    if (index > -1) {
      this.queriesArray[index] = queryDetails;
    } else {
      this.queriesArray.push(queryDetails);
    }
    this.returnQueries();
  }

  @Emit(`generate`)
  returnQueries() {
    return this.queriesArray;
  }
}
