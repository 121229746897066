







































import BasicBarChart from '@/components/swine/reports/BasicBarChart.vue';
import LineChart from '@/components/swine/reports/LineChart.vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: { BasicBarChart, LineChart },
})
export default class GraphToggle extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  chartData!: Chart.ChartData;

  @Prop({
    type: Object,
    default: null,
  })
  options!: Chart.ChartOptions;

  showBarChart = true;

  @Emit(`changeChart`)
  changeGraph() {
    this.showBarChart = !this.showBarChart;
    return this.showBarChart;
  }
}
