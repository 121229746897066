import Chart, {
  ChartLegendLabelItem,
  ChartOptions,
  PointStyle,
} from 'chart.js';

export const BRONCHOPNEUMONIA_SCORE_ANALYSIS_GRAPH_CONFIG = {
  elements: {
    point: {
      radius: 6,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    labels: {
      usePointStyle: true,
      padding: 25,
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          max: 120,
          beginAtZero: true,
          callback: function (tick: string | number) {
            return tick.toString() + `%`;
          },
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        offset: true,
      },
    ],
  },
};

export const LUNG_LESION_SCORE_SUMMARY_ANALYSIS_GRAPH_CONFIG = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 20,
    },
  },
  legend: {
    labels: {
      usePointStyle: true,
      padding: 30,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 120,
          callback: function (tick: string | number) {
            return tick.toString() + '%';
          },
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};

export const APP_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG: ChartOptions = {
  elements: {
    point: {
      radius: 6,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    labels: {
      usePointStyle: true,
      padding: 25,
      generateLabels: (chart: Chart) => {
        const newLabels: ChartLegendLabelItem[] = [];
        chart?.data?.datasets?.forEach((element, index) => {
          const meta = chart.getDatasetMeta(index);
          newLabels.push({
            text: element.label,
            fillStyle:
              element.label === 'APP Index'
                ? `#F5F5F5`
                : (element.backgroundColor as string),
            strokeStyle:
              element.label === 'APP Index'
                ? `#8E8E8E`
                : (element.borderColor as string),
            lineWidth: element.pointBorderWidth
              ? Number(element.pointBorderWidth)
              : 1,
            pointStyle: element.pointStyle as PointStyle,
            hidden: meta.hidden,
            datasetIndex: index,
          });
        });
        return newLabels;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 4,
          beginAtZero: true,
          callback: function (tick: number | string) {
            return tick.toString();
          },
        },
        position: `left`,
        id: `AppIndex`,
      },
      {
        ticks: {
          min: 0,
          max: 120,
          beginAtZero: true,
          callback: function (tick: number | string) {
            return tick.toString() + `%`;
          },
        },
        position: `right`,
        id: `Prevalence`,
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
      },
      {
        offset: true,
        display: false,
      },
    ],
  },
};

export const PNEUMONIA_INDEX_AND_PREVALENCE_GRAPH_ANALYSIS_CONFIG: ChartOptions = {
  elements: {
    point: {
      radius: 6,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    labels: {
      usePointStyle: true,
      padding: 25,
      generateLabels: (chart: Chart) => {
        const newLabels: ChartLegendLabelItem[] = [];
        chart?.data?.datasets?.forEach((element, index) => {
          const meta = chart.getDatasetMeta(index);
          newLabels.push({
            text: element.label,
            fillStyle:
              element.label === 'Pneumonia Index'
                ? `#F5F5F5`
                : (element.backgroundColor as string),
            strokeStyle:
              element.label === 'Pneumonia Index'
                ? `#8E8E8E`
                : (element.borderColor as string),
            lineWidth: element.pointBorderWidth
              ? Number(element.pointBorderWidth)
              : 1,
            pointStyle: element.pointStyle as PointStyle,
            hidden: meta.hidden,
            datasetIndex: index,
          });
        });
        return newLabels;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 6,
          beginAtZero: true,
          callback: function (tick: string | number) {
            return tick.toString();
          },
        },
        position: `left`,
        id: `PneumoniaIndex`,
      },
      {
        ticks: {
          min: 0,
          max: 120,
          beginAtZero: true,
          callback: function (tick: string | number) {
            return tick.toString() + `%`;
          },
        },
        position: `right`,
        id: `Prevalence`,
        gridLines: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        offset: true,
      },
      {
        offset: true,
        display: false,
      },
    ],
  },
};

export const ABSCESS_SCARRING_PERICARDITIS_GRAPH_ANALYSIS_CONFIG = LUNG_LESION_SCORE_SUMMARY_ANALYSIS_GRAPH_CONFIG;
export const PLEURISY_GRAPH_ANALYSIS_CONFIG = {
  elements: {
    point: {
      radius: 6,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    labels: {
      usePointStyle: true,
      padding: 25,
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          beginAtZero: true,
          callback: function (tick: string | number) {
            return tick.toString() + `%`;
          },
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        offset: true,
      },
    ],
  },
};
