























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import ReportSearchFilterDropdown from './ReportSearchFilterDropdown.vue';
import { AnalysisStores } from '@/store/swine/analysis';
import { AnalysisDropdownSelectionQueries } from '@/models/swine/analysis.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { convertDateToYYYYMMDDwithDashesAndUTC } from '@/utils/moment';
@Component({
  components: {
    ReportSearchFilterDropdown,
  },
})
export default class IndividualReportDropdown extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  queries!: { [key: string]: string | null };

  @Prop({
    type: Number,
    default: null,
  })
  selectedId!: number;

  @Prop({})
  resetQuery!: { [key: number]: boolean };

  @Prop({
    type: Date,
    default: null,
  })
  startDate!: Date;

  @Prop({
    type: Date,
    default: null,
  })
  endDate!: Date;

  store = AnalysisStores.detail;
  params: AnalysisDropdownSelectionQueries = {
    specifiedId: null,
    searchQuery: null,
    limit: 10,
    offset: 0,
  };
  reports: DropdownOption[] = [];
  selectedReport = '';
  selectedReportText = 'Select Report';
  totalReports = 0;
  currentDataIndex = 0;
  startPaginationCount = 0;
  endPaginationCount = 0;
  listLimit = 10;
  pageNumber = 1;

  updatePageNumber(value: number) {
    this.pageNumber += value;
    // we can do this since we know the value is either 1 or -1
    if (
      this.params &&
      this.params.offset !== null &&
      this.params.offset !== undefined
    ) {
      this.params.offset = this.params.offset + this.listLimit * value;
    }
    this.retrieveReports();
  }

  updateStartPaginationCount() {
    if (this.reports.length === 0) {
      this.startPaginationCount = 0;
    } else if (this.totalReports === 0) {
      this.startPaginationCount = 0;
    } else {
      this.startPaginationCount = 1 + this.listLimit * (this.pageNumber - 1);
    }
  }

  updateEndPaginationCount() {
    if (this.reports.length === 0) {
      this.endPaginationCount = 0;
    } else if (this.listLimit * this.pageNumber > this.totalReports) {
      this.endPaginationCount = this.totalReports;
    } else {
      this.endPaginationCount = this.listLimit * this.pageNumber;
    }
  }

  @Watch('resetQuery', { deep: true })
  resetDropdowns() {
    if (this.resetQuery && this.resetQuery[this.selectedId]) {
      for (const key in this.queries) {
        if (key !== 'comparison_type' && key !== 'query_id') {
          this.queries[key] = null;
        }
      }
      this.selectedReportText = `Select Report`;
      this.resetSelectedQuery();
      this.resetComplete();
    }
  }

  @Emit('updateQuery')
  resetSelectedQuery() {
    return this.queries;
  }

  @Emit('reset')
  resetComplete() {
    return this.selectedId;
  }

  mounted() {
    this.queries[`comparison_type`] = 'individual_report';
    this.params.specifiedId = this.selectedId;
    this.selectedReportText = 'Select Report';
    this.updateDropdownStates();
  }

  @Watch('startDate')
  @Watch('endDate')
  updateDropdownStates() {
    if (this.startDate && this.endDate) {
      this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
        this.startDate
      );
      this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(this.endDate);
      this.currentDataIndex = this.store.auditReports.findIndex((details) => {
        return details.id === this.selectedId;
      });
      if (!this.store.auditReports[this.currentDataIndex]) {
        this.store.getAllAuditReports(this.params);
      } else {
        this.updateOptions();
      }
    }
  }

  searchReports(query: string) {
    if (query.length > 0) {
      this.params.searchQuery = query;
    } else {
      this.params.searchQuery = null;
    }
    this.params.limit = 10;
    this.params.offset = 0;
    this.retrieveReports();
  }

  retrieveReports() {
    this.store.getAllAuditReports(this.params);
  }

  @Watch(`store.auditReports`, { deep: true })
  updateOptions() {
    if (this.store.auditReports.length >= 0) {
      const index = this.store.auditReports.findIndex((details) => {
        return details.id === this.params.specifiedId;
      });
      if (index > -1 && this.store.auditReports[index]) {
        this.reports = this.store.auditReports[index].data;
        this.totalReports = this.store.auditReports[index].totalCount as number;
      }
      if (this.queries[`report_id`]) {
        const index = this.reports.findIndex((details) => {
          return details.value === this.queries[`report_id`];
        });
        if (index > -1 && this.reports && this.reports[index]) {
          this.selectedReport = this.reports[index].value;
          this.selectedReportText = this.reports[index].text;
        }
      }
      this.updateStartPaginationCount();
      this.updateEndPaginationCount();
    }
  }

  @Emit('close')
  removeDropdown() {
    this.params.searchQuery = null;
    this.params.limit = 10;
    this.params.offset = 0;
    return this.selectedId;
  }

  @Emit(`updateQuery`)
  updateReportId(data: DropdownOption) {
    this.queries[`report_id`] = data.value;
    this.queries[`query_id`] = String(this.selectedId);
    this.selectedReportText = data.text;
    return this.queries;
  }
}
