
























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  SummaryAnalysis,
  SummaryAnalysisOutput,
} from '@/models/swine/analysis.model';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({})
export default class PneumoniaIndexAndPrevalenceAnalysisTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  data!: SummaryAnalysisOutput;

  @Prop({
    type: Boolean,
    default: false,
  })
  removeNumberOfLungsExamined!: boolean;

  tableData: SummaryAnalysis[] | null = null;

  standardizeValue(data: number) {
    return addDecimalPointToNumbers(data);
  }

  mounted() {
    if (this.data) {
      this.tableData = [];
      if (this.data.farmUnits) {
        this.tableData.push(...this.data.farmUnits);
      }
      if (this.data.industryAverages) {
        this.tableData.push(...this.data.industryAverages);
      }
      if (this.data.individualReports) {
        this.tableData.push(...this.data.individualReports);
      }
    }
  }

  generatePneumoniaIndexStyle(value: number) {
    if (value < 0.56) {
      return `background-color:#D6FFA4; color:#6c9d31;`;
    }
    if (value >= 0.56 && value <= 0.89) {
      return `background-color:#FFF084; color:#745E05;`;
    }
    if (value >= 0.9) {
      return `background-color:#FFEAEA; color:#FF4C4B;`;
    }
  }
}
