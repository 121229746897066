





















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '../DropdownAnalysisTemplate.vue';
import { AnalysisStores } from '@/store/swine/analysis';
import {
  AnalysisDropdownSelectionQueries,
  AnalysisParams,
} from '@/models/swine/analysis.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { convertDateToYYYYMMDDwithDashesAndUTC } from '@/utils/moment';
import { generateUniqueId } from '@/utils/lodash';
@Component({
  components: {
    Dropdown,
  },
})
export default class IndustryAverageDropdown extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  queries!: { [key: string]: string | null };

  @Prop({
    type: Number,
    default: null,
  })
  selectedId!: number;

  @Prop({})
  resetQuery!: { [key: number]: boolean };

  @Prop({
    type: Date,
    default: null,
  })
  startDate!: Date;

  @Prop({
    type: Date,
    default: null,
  })
  endDate!: Date;

  // Reason why we can do this is because we know what is the logical order
  keys = [`country`, `region`, `state`, `client`, `farm`, `farm_unit`];
  countries: DropdownOption[] | null = null;
  regions: DropdownOption[] | null = null;
  states: DropdownOption[] | null = null;
  clients: DropdownOption[] | null = null;
  farms: DropdownOption[] | null = null;
  farmUnits: DropdownOption[] | null = null;
  store = AnalysisStores.detail;
  params: AnalysisDropdownSelectionQueries = {
    specifiedId: null,
    countryId: null,
    regionId: null,
    stateId: null,
    clientId: null,
    farmId: null,
    farmUnitId: null,
    startDate: null,
    endDate: null,
  };
  selectedCountry = '';
  selectedCountryText = 'Country';

  @Watch('resetQuery', { deep: true })
  resetDropdowns() {
    if (this.resetQuery && this.resetQuery[this.selectedId]) {
      for (const key in this.queries) {
        if (key !== 'comparison_type' && key !== 'query_id') {
          this.queries[key] = null;
        }
      }
      this.selectedCountryText = `Country`;
      this.resetSelectedQuery();
      this.resetComplete();
    }
  }

  @Emit('updateQuery')
  resetSelectedQuery() {
    return this.queries;
  }

  @Emit('reset')
  resetComplete() {
    return this.selectedId;
  }

  mounted() {
    this.queries[`comparison_type`] = `industry_average`;
    this.params.specifiedId = this.selectedId;
    this.resetDropdown();
    this.selectedCountryText = `Country`;
    this.updateDropdownStates();
  }

  @Watch('startDate')
  @Watch('endDate')
  updateDropdownStates() {
    if (this.startDate && this.endDate) {
      this.params.startDate = convertDateToYYYYMMDDwithDashesAndUTC(
        this.startDate
      );
      this.params.endDate = convertDateToYYYYMMDDwithDashesAndUTC(this.endDate);
      const searchParams: AnalysisParams = {
        queries: this.params,
      };
      if (this.store.industryAverageCountries.length === 0) {
        this.store.getAllIndustryAverageCountries(searchParams);
      } else {
        this.updateCountryOptions();
      }
    }
  }

  repopulateDropdown() {
    if (this.queries && this.queries[`country`] && this.countries) {
      const index = this.countries.findIndex((details) => {
        return details.value === this.queries[`country`];
      });
      if (index > -1 && this.countries && this.countries[index]) {
        this.selectedCountry = this.countries[index].value;
        this.selectedCountryText = this.countries[index].text;
      }
    }
  }

  resetDropdown() {
    this.countries = [
      {
        text: `Country`,
        value: '-1',
        uniqueKey: `country_${this.params.specifiedId}`,
      },
    ];
  }

  @Watch(`store.industryAverageCountries`)
  updateCountryOptions() {
    if (this.store.industryAverageCountries.length >= 0) {
      this.countries = [];
      this.countries.push(
        {
          text: `All`,
          value: `all`,
          uniqueKey: generateUniqueId(`all_`),
        },
        ...this.store.industryAverageCountries
      );
      if (this.queries[`country`]) {
        this.repopulateDropdown();
      }
    }
  }

  @Emit(`updateQuery`)
  updateCountryId(option: DropdownOption) {
    this.queries[`country`] = option.value;
    this.queries[`query_id`] = String(this.selectedId);
    this.selectedCountryText = option.text;
    return this.queries;
  }

  @Emit('close')
  removeDropdown() {
    return this.selectedId;
  }
}
