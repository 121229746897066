





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  SummaryAnalysis,
  SummaryAnalysisOutput,
} from '@/models/swine/analysis.model';
import { addDecimalPointToNumbers } from '@/utils/formats';
@Component({})
export default class AbscessScarringPericarditisTable extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  data!: SummaryAnalysisOutput;

  @Prop({
    type: Boolean,
    default: false,
  })
  removeNumberOfLungsExamined!: boolean;

  tableData: SummaryAnalysis[] | null = null;

  standardizeValue(data: number) {
    return addDecimalPointToNumbers(data);
  }

  mounted() {
    if (this.data) {
      this.tableData = [];
      if (this.data.farmUnits) {
        this.tableData.push(...this.data.farmUnits);
      }
      if (this.data.industryAverages) {
        this.tableData.push(...this.data.industryAverages);
      }
      if (this.data.individualReports) {
        this.tableData.push(...this.data.individualReports);
      }
    }
  }
}
