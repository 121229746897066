






































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import DateDropdown from '@/components/swine/dropdowns/DateDropdown.vue';
import { ADD_COMPARISONS_DATA } from '@/utils/constants';
import FarmUnitAnalysisMultiDropdown from '../dropdowns/FarmUnitAnalysisMultiDropdown.vue';
import { AnalysisStores } from '@/store/swine/analysis';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { getLastDayOfMonth } from '@/utils/moment';
@Component({
  components: {
    DateDropdown,
    FarmUnitAnalysisMultiDropdown,
  },
})
export default class MonthAnalysis extends Vue {
  addComparisonOptions: DropdownOption[] = [];
  startDate: Date | null = null;
  endDate: Date | null = null;
  monthQueryDict: { [key: string]: string | null } | null = null;
  resetQueries: { [key: number]: boolean } | null = null;
  resetStartDateFilter = false;
  resetEndDateFilter = false;
  store = AnalysisStores.detail;
  // Reason why we can do this is because we know the logical order of things
  keys = [
    `query_id`,
    `country`,
    `region`,
    `state`,
    `client`,
    `farm`,
    `farm_unit`,
    `report_id`,
    `comparison_type`,
  ];

  @Prop({
    type: Boolean,
    default: false,
  })
  resetQueryFilters!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDateFilters!: boolean;

  @Watch(`resetQueryFilters`)
  resetAllFilters() {
    if (this.resetQueryFilters) {
      this.resetQueries = {};
      // We can do this since there is only 1
      this.resetQueries[5] = true;
    }
  }

  @Watch(`resetDateFilters`)
  resetDateSelections() {
    if (this.resetDateFilters) {
      this.resetStartDateFilter = true;
      this.resetEndDateFilter = true;
    }
  }

  resetStartDateComplete(value: boolean) {
    if (this.resetStartDateFilter) {
      this.resetStartDateFilter = value;
      this.resetDateProcessComplete();
    }
  }

  resetEndDateComplete(value: boolean) {
    if (this.resetEndDateFilter) {
      this.resetEndDateFilter = value;
      this.resetDateProcessComplete();
    }
  }

  @Emit('resetDates')
  resetDateProcessComplete() {
    if (!this.resetStartDateFilter && !this.resetEndDateFilter) {
      this.startDate = null;
      this.endDate = null;
      return false;
    }
  }

  resetQueryUpdate(value: number) {
    if (this.resetQueries && this.resetQueries[value] !== null) {
      this.resetQueries[value] = false;
    }
    this.resetProcessComplete();
  }

  @Emit('reset')
  resetProcessComplete() {
    let resetQueriesFilter = false;
    for (const key in this.resetQueries) {
      if (this.resetQueries && this.resetQueries[parseInt(key)] === true) {
        resetQueriesFilter = true;
      }
    }
    if (!resetQueriesFilter) {
      this.resetQueries = null;
      this.store.replaceCountryOptions([]);
      this.store.replaceRegionOptions([]);
      this.store.replaceStateOptions([]);
      this.store.replaceClientOptions([]);
      this.store.replaceFarmOptions([]);
      this.store.replaceFarmUnitOptions([]);
      this.returnQueries();
      return false;
    }
  }

  // We can set it to be 1 since there will only be 1 row
  get defaultId() {
    return 5;
  }

  mounted() {
    this.addComparisonOptions = ADD_COMPARISONS_DATA;
    this.monthQueryDict = this.createBaseQueriesDict();
    this.monthQueryDict[`comparison_type`] = `farm_unit`;
    this.monthQueryDict[`query_id`] = String(0);
  }

  createBaseQueriesDict() {
    const queries: { [key: string]: string | null } = {};
    this.keys.map((key) => {
      queries[key] = null;
    });
    return queries;
  }

  @Emit('selectStartDate')
  setStartDate(date: Date) {
    this.startDate = date;
    return this.startDate;
  }

  @Emit('selectEndDate')
  setEndDate(date: Date | null) {
    this.endDate = date ? getLastDayOfMonth(date) : date;
    return this.endDate;
  }

  removeDropdown() {
    // Remove the existing selection of things here
    // We will need to sync the ids up so it is in order
    this.returnQueries();
  }

  updateQueryDetails(queryDetails: { [key: string]: string | null }) {
    this.monthQueryDict = queryDetails;
    this.returnQueries();
  }

  @Emit(`generate`)
  returnQueries() {
    return this.monthQueryDict;
  }
}
